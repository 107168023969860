import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from '../../services/session.service';
import { UtilsService } from '../../services/utils.service';
import { Application } from '../../models/application';


declare var $;
declare var unlayer;


@Component({
    selector : 'app-application-basic-form',
    templateUrl	: './applicationMailing.component.html'
})

export class ApplicationMailingFormComponent implements OnInit {

		private application = new Application();
		private application_id = 0;
		private param: any;
		private title = 'CREAR CAMPAÑA';
		private clients: any;
		private divisions: any;
		private r_fields: any;
		private fields: any;
		private email_json: string;
		private email_active: any;
		private email_subject: string;
		private email_content: string;
		private image: any;
		private response: any;
		private varForm = true;
		private ss: any;
	

    constructor (
        private router: Router,
		private route: ActivatedRoute,
		public session: SessionService,
        private utils: UtilsService
    ) { }

    ngOnInit() {
        this.param = this.route.params.subscribe(
            params => {
                if (params['id']) {
                    this.application_id = params['id'];
                    this.title = 'CREAR O ACTUALIZAR EMAIL DE CAMPAÑA';
                }
            }
        );
		this.ss = sessionStorage;
		this.getFields();
		this.getApplication();
		this.setArrays();
		
		var getUrlUploadimages =  this.session.url; 
		//var getAppId = this.application_id;
		//console.log(getAppId);	

		unlayer.init({
			id: 'editor-container',
			locale: 'es-ES',
			displayMode: "email",
			projectId: 2076,
			//templateId: 5675,
			appearance: {
				theme: 'light'
			  },
			  tools: {
					form: {
						enabled: false
					},
					html: {
						enabled: false
						}			
				}  
		  });

		  
		
		unlayer.addEventListener('design:updated', function(data) {
			if(data.type == "content:removed" && data.item.type == "image"){
				//alert("borrando imagen");
				//console.log(data.item.values.src.url);
				var url_string_image = data.item.values.src.url; //window.location.href
				var url_image = new URL(url_string_image);
				var id_image = url_image.searchParams.get("id_image");
				deletedImage(id_image);
				//console.log(id_image);
				
			}
			unlayer.exportHtml(function(data) {
				var json = data.design; // design json
				var myJSONMailing = JSON.stringify(json);
				$("body").attr("jsonDesign", myJSONMailing);
				var htmlMailing = data.html; // final html
				$("body").attr("mailingDesignHTML", htmlMailing);
			});

		  });
		

			//file storage
			

	function deletedImage(id_image){

		var data = new FormData();
		data.append('email_images_id', id_image);


		fetch(getUrlUploadimages+'api/application/custom/delete_email_image', {
			method: 'POST',
			headers: {
				'Accept': 'application/json',
				'Authorization': "Bearer " +sessionStorage.getItem('token')
			},
			body: data
			}).then(response => {
			// Make sure the response was valid
			
			if (response.status >= 200 && response.status < 300) {
				
				return response;
				
			} else {
				var error = new Error(response.statusText)
				//error.response = response
				throw error
			}
			}).then(response => {
				
			return response.json()
			}).then(data => {
				// Pass the URL back to Unlayer to mark this upload as completed
				//console.log(data);
			});

		
	}

	

	
	
	
	unlayer.registerCallback('image', function(image, done) {
		var data = new FormData();
		var getApplication_id = $("body").attr("getApplication_id");
		data.append('application_id', getApplication_id);
		data.append('image', image.attachments[0]);

		var formimages = data.append('image', image.attachments[0]);
		console.log(formimages);

	  fetch(getUrlUploadimages+'api/application/custom/store_email_image', {
		method: 'POST',
		headers: {
		  'Accept': 'application/json',
		  'Authorization': "Bearer " +sessionStorage.getItem('token')
		},
		body: data
	  }).then(response => {
		// Make sure the response was valid
		
		if (response.status >= 200 && response.status < 300) {
			
		  return response;
		  
		} else {
		  var error = new Error(response.statusText)
		  //error.response = response
		  throw error
		}
	  }).then(response => {
			
		return response.json()
	  }).then(data => {
		// Pass the URL back to Unlayer to mark this upload as completed
		//console.log(data.data.image);
		done({ progress: 100, url: data.data.image+"?id_image="+data.data.email_images_id });
		//this.attr("id-image", data.data.email_images_id);
		//console.log(data);
	  })
	})
	
	//end file storage

		  

	} //nginit
	


	


	getFields()
	{
		var self = this;
		this.utils.httpGet(true, false, 'api/field/show_by_required/1').then(function(r) {
			if (r) { 

			}
		});

	
    }

    getApplication()
	{
		var self = this;
		$("body").attr("email-active", "0");
		$("body").attr("getApplication_id", this.application_id);

		this.utils.httpGet(false, false, 'api/application/' + this.application_id).then(function(r) {
			if (r) {
				
				self.application.setApplication(r); 
				//console.log(self.application);
				var getContentJson = self.application.email_json;
				var getContentHtml = self.application.email_content;
				var getContentSubject = self.application.email_subject;
				var getContentActive = self.application.email_active;
				if(getContentJson != null || getContentJson != "undefined"){
					//var design = {}; // template JSON
					var design = self.application.email_json;
					if(getContentJson != "undefined"){
						var gJsonFormated = JSON.parse(design);
					}else{
						gJsonFormated = "";
					}
					
					design = gJsonFormated; // template JSON
					unlayer.loadDesign(design);

					$("body").attr("jsondesign", getContentJson);
					$("body").attr("mailingDesignHTML", getContentHtml);

					if(getContentSubject != "undefined"){
						$("#email_subject").val(getContentSubject);
					}else{
						$("#email_subject").val("");
					}
					
					if(getContentActive === 0){
						$(".opt-array").val("0").prop('checked', false);
					}else{
						
						$(".opt-array").val("1").prop('checked', true);
						
					}

					if($(".opt-array").prop('checked')) {
						$(this).find(".opt-array").val("1");
						var getValueActive = $(".opt-array").val();
						$("body").attr("email-active", getValueActive);
					}

				}else{
					$("body").attr("jsondesign", "");
					$("body").attr("mailingDesignHTML", "");
					$("body").attr("email-active", "");
					$(".opt-array").val("0").attr( 'checked', 'false' );
				}
				

		
			}
		});



	}
		

	gCreateMailing() {	
		var email_active = $("body").attr("email-active");
		var getjsonDesign = $("body").attr("jsondesign");
		var getHtmlDesign = $("body").attr("mailingDesignHTML");
		var getSubject = $("#email_subject").val();
		var list = {
			"application_id": this.application_id,
			"email_active": email_active,
			"email_subject": getSubject,
			"email_json": getjsonDesign,
			"email_content": getHtmlDesign
		};
		return list;	
	}


    saveApplicationMailing() {
		const self = this;
	
		$('#main-form').fadeIn();
		
		//console.log(this.application.gCreateMailing(this.application, this.application_id));
		this.utils.httpPost(true, true, 'api/application/custom/store_email', this.gCreateMailing(), true)
			.then(function(r) {
			if (r) {

				self.response = r;
				//console.log("campaña actualizada: ", r.email_content);

				localStorage.setItem('email_content_last', r.email_content);
				$(".atd_basic-preview").removeClass("hide").addClass("zoomInDown");

			}
			})

	}
	


    volver() {
        this.router.navigate(['application_basic/']);
	}

	setArrays()
	{

		$(document).on("click", ".switch", function(){

			if($(".opt-array").prop('checked')) {
				$(this).find(".opt-array").val("1");
				var getValueActive = $(".opt-array").val();
				$("body").attr("email-active", getValueActive);
				//console.log(getValueActive);
			} else {
				$(this).find(".opt-array").val("0");
				var getValueActive = $(".opt-array").val();
				$("body").attr("email-active", getValueActive);
				//console.log(getValueActive);
			}

		})

		
		
	}
	


	createPreviewmailing(){
		var share_link ="assets/preview.html";
		window.open(share_link, '', 'scrollbars=1,height=500,width=700,left=500,top=100');
	}



   
}
