import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

// Import Basics
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';

// Import Components
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { LoginRecoverComponent } from './login/recover/recover.component';
import { HomeComponent } from './home/home.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';

import { UserComponent } from './user/user.component';
import { UserShowComponent } from './user/show/show.component';
import { UserFormComponent } from './user/form/form.component';

import { AllyComponent } from './ally/ally.component';
import { AllyShowComponent } from './ally/show/show.component';
import { AllyFormComponent } from './ally/form/form.component';

import { AgentComponent } from './agent/agent.component';
import { AgentShowComponent } from './agent/show/show.component';
import { AgentFormComponent } from './agent/form/form.component';

import { ApplicationComponent } from './application/application.component';
import { ApplicationShowComponent } from './application/show/show.component';
import { ApplicationFormComponent } from './application/form/form.component';
import { ApplicationAnalyticsComponent } from './application/analytics/analytics.component';

import { ApplicationBasicComponent } from './application_basic/applicationBasic.component';
import { ApplicationBasicFormComponent } from './application_basic/form/form.component';

import { ApplicationMailingFormComponent } from './application_mailing/form/applicationMailing.component';
import { RegistrosComponent } from './registros/registros.component';


import { StatsRecordsComponent } from './stats/records/records.component';

// Import Fragments
import { MenuComponent } from './fragment/menu/menu.component';
import { HeaderComponent } from './fragment/header/header.component';
import { FooterComponent } from './fragment/footer/footer.component';
import { RightSidebarComponent } from './fragment/right-sidebar/right-sidebar.component';

// Import Services
import { SessionService } from './services/session.service';
import { UtilsService } from './services/utils.service';

// Import Directives
import { LoaderContentDirective } from './directives/loader-content.directive';
import { TooltipDirective } from './directives/tooltip.directive';
import { BackDirective } from './directives/back.directive';

// Import Third Modules
import { Ng2Permission } from 'angular2-permission/dist';




@NgModule({
    declarations: [
        // Fragments
        HeaderComponent,
        MenuComponent,
        FooterComponent,
        RightSidebarComponent,

        // Directive
        LoaderContentDirective,
        TooltipDirective,
        BackDirective,

        // Components
        AccessDeniedComponent,
        AppComponent,
        LoginComponent,
        LoginRecoverComponent,
        HomeComponent,
        UserComponent,
        UserShowComponent,
        UserFormComponent,
        AllyComponent,
        AllyShowComponent,
        AllyFormComponent,
        AgentComponent,
        AgentShowComponent,
        AgentFormComponent,
        ApplicationComponent,
        ApplicationShowComponent,
        ApplicationFormComponent,
        ApplicationAnalyticsComponent,
        ApplicationBasicComponent,
        ApplicationBasicFormComponent,
        ApplicationMailingFormComponent,
        StatsRecordsComponent,
        RegistrosComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        AppRoutingModule,
        RouterModule,
        Ng2Permission
    ],
    providers: [SessionService, UtilsService],
    bootstrap: [AppComponent]
})
export class AppModule { }
