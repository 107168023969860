import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from '../services/session.service';
import { UtilsService } from '../services/utils.service';
import { User } from '../models/user';

declare var $;

@Component({
    selector    : 'app-user',
    templateUrl : './user.component.html'
})

export class UserComponent implements OnInit
{
    private users = [];
    private user  = new User();

    constructor (
        private title: Title,
        private session: SessionService,
        private utils: UtilsService
    ) { }

    ngOnInit() {
        this.title.setTitle('Formularios Únicos - Usuarios');
        this.getUsers();
    }

    getUsers() {
        const self = this;

        this.utils.httpGet(true, false, 'api/user').then(function(r) {
            if (r) {
                Object.keys(r).map(function(i) { self.users.push(r[i]); });
                self.session.loadScript(self.session.getCmsUrl() + '/assets/Customjs/pages/tables/jquery-datatable.js');
            }
        });
    }
}
