export class Ally
{
	public ally_id				: number;
	public city_id				: any;
	public id_type_id			: any;
	public id_number			: string;
	public name						: string;
	public email					: string;
	public phone					: string;
	public mobile					: string;
	public address				: string;
	public website				: string;
	public contact_name		: string;
	public contact_email	: string;
	public contact_mobile : string;
	public state          : string;

	constructor()
	{
		this.ally_id				= null;
		this.city_id				= [];
		this.id_type_id			= [];
		this.id_number			= '';
		this.name						= '';
		this.email					= '';
		this.phone					= '';
		this.mobile					= '';
		this.address				= '';
		this.website				= '';
		this.contact_name		= '';
		this.contact_email	= '';
		this.contact_mobile = '';
		this.state				  = '';
	}

	setAlly(ally)
	{
		this.ally_id				= ally.ally_id;
		this.city_id				= (ally.city_id) ? ally.city_id : [];
		this.id_type_id			= (ally.id_type_id) ? ally.id_type_id : [];
		this.id_number			= ally.id_number;
		this.name						= ally.name;
		this.email					= ally.email;
		this.phone					= ally.phone;
		this.mobile					= ally.mobile;
		this.address				= ally.address;
		this.website				= ally.website;
		this.contact_name		= ally.contact_name;
		this.contact_email	= ally.contact_email;
		this.contact_mobile = ally.contact_mobile;
		this.state				  = ally.state;
	}

	gCreate(ally)
	{
		return {
			'city_id'				 : ally.city_id['city_id'],
			'id_type_id'		 : ally.id_type_id['id_type_id'],
			'id_number'			 : ally.id_number,
			'name'					 : ally.name,
			'email'					 : ally.email,
			'phone'					 : ally.phone,
			'mobile'				 : ally.mobile,
			'address'				 : ally.address,
			'website'				 : ally.website,
			'contact_name'	 : ally.contact_name,
			'contact_email'	 : ally.contact_email,
			'contact_mobile' : ally.contact_mobile,
			'update'				 : (ally.ally_id) ? true : false
		};
	}

	gUpdate(ally)
	{
		return {
			'city_id'				 : ally.city_id['city_id'],
			'id_type_id'		 : ally.id_type_id['id_type_id'],
			'id_number'			 : ally.id_number,
			'name'					 : ally.name,
			'email'					 : ally.email,
			'phone'					 : ally.phone,
			'mobile'				 : ally.mobile,
			'address'				 : ally.address,
			'website'				 : ally.website,
			'contact_name'	 : ally.contact_name,
			'contact_email'	 : ally.contact_email,
			'contact_mobile' : ally.contact_mobile,
			'state'					 : (ally.state) ? '1' : '0'
		};
	}
}
