import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from '../../services/session.service';
import { UtilsService } from '../../services/utils.service';
import { Application } from '../../models/application';

declare var $;

@Component({
    selector : 'app-application-basic-form',
    templateUrl	: './form.component.html'
})

export class ApplicationBasicFormComponent implements OnInit {

	private application = new Application();
    private application_id = 0;
    private param: any;
    private title = 'CREAR CAMPAÑA';
    private clients: any;
    private divisions			 : any;
    private r_fields       : any;
	private fields				 : any;
    private response: any;
    private varForm = true;
    private ss: any;

    constructor (
        private router: Router,
        private route: ActivatedRoute,
        private session: SessionService,
        private utils: UtilsService
    ) { }

    ngOnInit() {
        this.param = this.route.params.subscribe(
            params => {
                if (params['id']) {
                    this.application_id = params['id'];
                    this.title = 'ACTUALIZAR CAMPAÑA';
                }
            }
        );
        this.ss = sessionStorage;
        this.getClients();
        this.getDivisions();
        this.getFields();
    }

    getClients() {
        const self = this;

        this.utils.httpGet(false, false, 'api/client/').then(function(r) {
            if (r) {
                self.clients = r;
                if (!self.application.client_id.client_id) { self.application.client_id.client_id = null; }

                $('#main-form').fadeOut();
                self.session.loadScript(self.session.getCmsUrl() + '/assets/Customjs/inputs.js');
            }
        });
    }

    getDivisions()
	{
		var self = this;

		this.utils.httpGet(false, false, 'api/division/').then(function(r) {
			if (r) self.divisions = r; 
		});
	}

	getFields()
	{
		var self = this;

		this.utils.httpGet(false, false, 'api/field/show_by_required/0').then(function(r) {
			if (r) { 
				self.fields = r; 

				//if (self.application_id) self.getApplication();
			}
			
			self.session.loadScript(self.session.getCmsUrl() + '/assets/Customjs/inputs.js');
		});

		this.utils.httpGet(true, false, 'api/field/show_by_required/1').then(function(r) {
			if (r) { 
				self.r_fields = r; 

				setTimeout(() => {
					$('input').selectpicker('refresh');
				}, 1);
			}
		});
    }

    

    /*setPath()
	{
			this.application.path = $.trim((this.application.name)).normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '')
				.replace(/_/g, ' ')
				.replace(/  +/g, ' ')
				.replace(/ /g, '-')
				.replace(/_ +/g, '-')
				.toLowerCase();

			$('#path').val(this.application.path);

			setTimeout(() => {
				$('input').selectpicker('refresh');
			}, 1);
	}*/
    
    //Obtener valores de checkboxes y dates para poder guardar
	setArrays()
	{
		var self = this;

		self.application.division_id = [];
		self.application.field_id		 = [];
		self.application.ally_id     = [];
		self.application.agent_id    = [];

		$('.opt-array').each(function() {
			var id    = $(this).prop('id');
			var tag   = $(this).prop('tagName');
			var value = null;

			if (id) {
				if (tag == 'INPUT') if ($(this).prop('checked')) value = $(this).val();

				if (tag == 'SELECT') value = $(this).prop('value');

				if (value && value > 0) self.application[id].push(value); 
			}
		});
		
		$('.date').each(function() { self.application[$(this).prop('id')] = $(this).val(); });
    }
    
    getApplication()
	{
		var self = this;

		this.utils.httpGet(false, false, 'api/application/' + this.application_id).then(function(r) {
			if (r) {
				self.application.setApplication(r);
				//self.name = self.application.name;
	
				for (const d of r['division_id']) $('#division_id[value="' + d.division_id + '"]').prop('checked', true);

				for (const d of r['field_id']) $('#field_id[value="' + d.field_id + '"]').prop('checked', true);
				
				for (const d of r['field_id']) $('#field_id[value="' + d.field_id + '"]').prop('checked', true);

				if (r['exp_m_img']) $('#s_exp').click();

				if (r['close_m_img']) $('#s_close').click();

				setTimeout(() => {
					$('input').selectpicker('refresh');
				}, 1);

				//if (r['ally_id'].length > 0) self.loadArrays(r);
				self.utils.fixInputs();
			}
		});
	}

    saveApplication() {
        const self = this;

        $('#main-form').fadeIn();

        this.setArrays();

        console.log(this.application.gCreateBasic(this.application));
        this.utils.httpPost(true, true, 'api/application/custom/storeBasic', this.application.gCreateBasic(this.application), true)
            .then(function(r) {
                if (r) {
                    self.varForm = false;
                    self.response = r;
                    //console.log(self.response);
                }
            });
    }

    volver() {
        this.router.navigate(['application_basic/']);
    }

    	//Chuleo y deschuleo campos obligatorios no obligatorios
	checkField(event) 
	{
		$('.opt-array').each(function(e) {
			if ($(this).prop('name') == event.target.name && $(this).prop('value') != event.target.value) {
				$(this).prop('checked', false);
            }	
           
		});
	}

   
}
