import { Component, OnInit }									 from '@angular/core';
import { Title }									             from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router }															 from '@angular/router';
import { PermissionService }									 from 'angular2-permission/dist';
import { SessionService }											 from '../services/session.service';
import { UtilsService }                        from '../services/utils.service';
import { isUndefined }												 from 'util';

@Component({
    selector    : 'app-login',
    templateUrl : './login.component.html'
})

export class LoginComponent implements OnInit 
{
	private user     : string;
  private password : string;

  constructor
	(
		private title      : Title,
    private router     : Router,
    private permission : PermissionService,
		private utils      : UtilsService
  ) { }

  ngOnInit() 
	{
		this.title.setTitle('Formularios Únicos');
    if (sessionStorage.getItem('token')) this.router.navigate(['application']);
  }

  login() 
	{
	  var self = this;

		var params = {
			'user'		 : this.user,
			'password' : this.password
		};
	
		this.utils.httpPost(true, false, 'api/login', params).then(function(r) {
			if (r) {
				if (r['user_rol_id'] != 5) { 
					for (let key of Object.keys(r)) sessionStorage.setItem(key, r[key]);
					self.permission.add(sessionStorage.getItem('user_rol_id'));
					self.router.navigate(['application']);
				} else {
					self.utils.showMsg('Acceso denegado a promotores', 'error');	
				}
			}	
		});
  }
}
