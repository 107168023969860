import { Component, OnInit }			 from '@angular/core';
import { Router, ActivatedRoute }  from '@angular/router';
import { SessionService }					 from '../../services/session.service';
import { UtilsService }            from '../../services/utils.service';
import { Ally }										 from '../../models/ally';

declare var $;

@Component({
  selector		: 'app-ally-form',
  templateUrl	: './form.component.html'
})

export class AllyFormComponent implements OnInit 
{
	private ally				  = new Ally();
	private ally_id			  = 0;
	private department_id = 0;
	private param				  : any;
	private title					= 'CREAR EMPRESA ALIADA';
	private departments		: any;
	private cities				: any;
	private id_types			: any;
	private success  			: any;

  constructor
	(
    private router  : Router,
    private route		: ActivatedRoute,
    private session : SessionService,
		private utils		: UtilsService
	) { }

  ngOnInit() 
	{
		this.param = this.route.params.subscribe(
			params => {
				if (params['id']) {
					this.ally_id = params['id'];
					this.title	 = 'ACTUALIZAR EMPRESA ALIADA';
					this.getAlly();
				}
			}
		);
	
		this.success = false;
		this.steps();
		this.getDepartments();
		this.getIdTypes();
  }

	steps()
	{
		var self = this;

		$('.steps').steps({
			enableCancelButton : true,
			labels : {
				next		 : 'Siguiente',
				previous : 'Anterior',
				finish   : 'Guardar',
				cancel   : 'Cancelar'
			},
			onStepChanging : function(e, currentIndex, newIndex) {
				if (currentIndex < newIndex && !self.success) {
					$('#main-form').fadeIn();

					self.utils.httpStep('ally', currentIndex, self.ally.gCreate(self.ally), false).then(function(r) {
						if (r) { 
							self.success = true;
							$('a[href="#next"]').click();
						}
					});
					return self.success;
				} else {
					return true;
				}
			},			
			onStepChanged : function(e, currentIndex, priorIndex) {
				$('#main-form').fadeOut();
				self.success = false;
			},
			onFinishing : function(e, currentIndex) {
				$('#main-form').fadeIn();

				self.utils.httpStep('ally', currentIndex, self.ally.gCreate(self.ally), false).then(function(r) {
					if (r) { 
						self.success = true;
						self.saveAlly();
					}
				});

				return self.success;
			},
			onCanceled : function(e, currentIndex) {
				self.router.navigate(['ally']);
			}
		});
	}

	getAlly()
	{
		var self = this;

		this.utils.httpGet(false, false, 'api/ally/' + this.ally_id).then(function(r) {
			if (r) {
				self.ally.setAlly(r);
				self.department_id = r['city_id']['department_id']['department_id'];
				self.getCities(self.department_id);
				self.ally.city_id.city_id = r['city_id']['city_id'];
			}
		});
	}

	getDepartments()
	{
		var self = this;

		this.utils.httpGet(false, false, 'api/department/').then(function(r) {
			if (r) {
				self.departments = r;

				if (!self.department_id) self.department_id = null; 
				if (!self.ally.city_id.city_id) self.ally.city_id.city_id = null;
			}
		});
	}

	getCities(id)
	{
		var self = this;

		this.utils.httpGet(false, false, 'api/city/show_by_department_id/'+ id).then(function(r) {
			if (r) self.cities = r; 
		});
	}
	
	getIdTypes()
	{
		var self = this;

		this.utils.httpGet(true, false, 'api/id_type/show_by_entity/1').then(function(r) {
			if (r) { 
				self.id_types = r;

				if (self.ally.id_type_id.length == 0) self.ally.id_type_id.id_type_id = null; 
			}

			self.session.loadScript(self.session.getCmsUrl() + '/assets/Customjs/inputs.js');
			setTimeout(() => {
				$('input').selectpicker('refresh');
			}, 1);
		});
	}

	saveAlly()
	{
		var self = this;
		
		$('#main-form').fadeIn();

		if (this.ally_id) {
			this.utils.httpPut(true, true, 'api/ally/' + this.ally_id, this.ally.gUpdate(this.ally)).then(function(r) {
				if (r) self.router.navigate(['ally/show/' + self.ally_id]);
			});
		} else {
			this.utils.httpPost(true, true, 'api/ally', this.ally.gCreate(this.ally)).then(function(r) {
				if (r) self.router.navigate(['ally/show/' + r.ally_id]);
			});
		}
	}
}
