import { Component, OnInit }			 from '@angular/core';
import { Router, ActivatedRoute }  from '@angular/router';
import { UtilsService }            from '../../services/utils.service';
import { Ally }										 from '../../models/ally';

@Component({
  selector		: 'app-ally-show',
  templateUrl	: './show.component.html'
})

export class AllyShowComponent implements OnInit 
{
	private ally  		= new Ally();
	private ally_id   = 0;
	private param		  : any;

  constructor
	(
    private router : Router,
    private route	 : ActivatedRoute,
		private utils	 : UtilsService
	) { }

  ngOnInit() 
	{
		this.param = this.route.params.subscribe(
			params => {
				this.ally_id = params['id']
			}
		);

		this.getAlly();
  }

	getAlly()
	{
		var self = this;

		this.utils.httpGet(true, false, 'api/ally/' + this.ally_id).then(function(r) {
			if (r) self.ally.setAlly(r);
		});
	}
}
