import { NgModule, Directive } from '@angular/core';
import { CommonModule, LocationStrategy, HashLocationStrategy, PathLocationStrategy, APP_BASE_HREF } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

// Import All Routes of App
import { LoginComponent } from './login/login.component';
import { LoginRecoverComponent } from './login/recover/recover.component';
import { HomeComponent } from './home/home.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';

import { UserComponent} from './user/user.component';
import { UserShowComponent } from './user/show/show.component';
import { UserFormComponent } from './user/form/form.component';

import { AllyComponent } from './ally/ally.component';
import { AllyShowComponent } from './ally/show/show.component';
import { AllyFormComponent } from './ally/form/form.component';

import { AgentComponent } from './agent/agent.component';
import { AgentShowComponent } from './agent/show/show.component';
import { AgentFormComponent } from './agent/form/form.component';

import { ApplicationComponent } from './application/application.component';
import { ApplicationShowComponent } from './application/show/show.component';
import { ApplicationFormComponent } from './application/form/form.component';
import { ApplicationAnalyticsComponent } from './application/analytics/analytics.component';

import { ApplicationBasicComponent } from './application_basic/applicationBasic.component';
import { ApplicationBasicFormComponent } from './application_basic/form/form.component';

import { ApplicationMailingFormComponent } from './application_mailing/form/applicationMailing.component';

import { StatsRecordsComponent } from './stats/records/records.component';
import { RegistrosComponent } from './registros/registros.component';

const routes: Routes = [
    { path: '', redirectTo: '/login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent, pathMatch: 'full'},
    { path: 'login/recover', component: LoginRecoverComponent, pathMatch: 'full'},
    { path: 'login/recover/:token', component: LoginRecoverComponent, pathMatch: 'full'},
    { path: 'home', component: HomeComponent, pathMatch: 'full' },
    // User
    { path: 'user', component: UserComponent, pathMatch: 'full' },
    { path: 'user/show/:id', component: UserShowComponent, pathMatch: 'full' },
    { path: 'user/create', component: UserFormComponent, pathMatch: 'full' },
    { path: 'user/update/:id', component: UserFormComponent, pathMatch: 'full' },
    { path: 'user/profile/:id', component: UserFormComponent, pathMatch: 'full' },
    // Ally
    { path: 'ally', component: AllyComponent, pathMatch: 'full' },
    { path: 'ally/show/:id', component: AllyShowComponent, pathMatch: 'full' },
    { path: 'ally/create', component: AllyFormComponent, pathMatch: 'full' },
    { path: 'ally/update/:id', component: AllyFormComponent, pathMatch: 'full' },
    // Agent
    { path: 'agent', component: AgentComponent, pathMatch: 'full' },
    { path: 'agent/show/:id', component: AgentShowComponent, pathMatch: 'full' },
    { path: 'agent/create', component: AgentFormComponent, pathMatch: 'full' },
    { path: 'agent/update/:id', component: AgentFormComponent, pathMatch: 'full' },
    // Application SmartBox
    { path: 'application', component: ApplicationComponent, pathMatch: 'full' },
    { path: 'application/show/:id', component: ApplicationShowComponent, pathMatch: 'full' },
    { path: 'application/create', component: ApplicationFormComponent, pathMatch: 'full' },
    { path: 'application/update/:id', component: ApplicationFormComponent, pathMatch: 'full' },
    { path: 'application/analytics/:id', component: ApplicationAnalyticsComponent, pathMatch: 'full' },
    // Application Sencillas
    { path: 'application_basic', component: ApplicationBasicComponent, pathMatch: 'full' },
    { path: 'application_basic/create', component: ApplicationBasicFormComponent, pathMatch: 'full' },
    // Mailing
    { path: 'application_mailing/create/:id', component: ApplicationMailingFormComponent, pathMatch: 'full' },

    
    // Stats
    { path: 'stats/records', component: StatsRecordsComponent, pathMatch: 'full'},
    { path: 'stats/registred', component: RegistrosComponent, pathMatch: 'full'},
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(routes, { useHash: true })
    ],
    declarations: [],
    exports: [RouterModule]
    // providers: [{ provide: APP_BASE_HREF, useValue: "/core/user" }, {provide: LocationStrategy, useClass: HashLocationStrategy}],
})

export class AppRoutingModule {}
