import { Component, OnInit }			 from '@angular/core';
import { Router, ActivatedRoute }  from '@angular/router';
import { SessionService }					 from '../../services/session.service';
import { UtilsService }            from '../../services/utils.service';
import { User }										 from '../../models/user';
import { UserInfo }								 from '../../models/user_info';

declare var $;

@Component({
	selector		: 'app-agent-form',
	templateUrl	: './form.component.html'
})

export class AgentFormComponent implements OnInit
{
	private user					 = new User();
	private user_info			 = new UserInfo();
	private user_id				 = 0;
	private c_password		 = '';
	private department_id  = 0;
	private param					 : any;
	private title					 = 'CREAR PROMOTOR';
	private roles					 : any;
	private clients				 : any;
	private allies				 : any;
	private departments		 : any;
	private cities				 : any;
	private id_types			 : any;
	private applications   : any;
	private r_applications : any;
	private genders				 : any;

	constructor
	(
		private router  : Router,
		private route		: ActivatedRoute,
		private session : SessionService,
		private utils		: UtilsService
	) { }

	ngOnInit()
	{
		this.param = this.route.params.subscribe(
			params => {
				if (params['id']) {
					this.user_id = params['id'];
					this.title	 = 'ACTUALIZAR PROMOTOR';
					this.getUser();
				}
			}
		);

		$('.date').bootstrapMaterialDatePicker({format: 'YYYY-MM-DD', time: false});
		this.getGenders();
		this.getClients();
		this.getAllies();
		this.getDepartments();
		this.getIdTypes();
		this.getApplications();
	}

	getUser()
	{
		var self = this;

		this.utils.httpGet(false, false, 'api/user/' + this.user_id).then(function(r) {
			if (r) {
				self.user.setUser(r);
				self.department_id = r['department_id'];
				self.getCities(self.department_id);
				self.user.city_id.city_id = r['city_id'];
			}
		});
	}

	getGenders()
	{
		var self     = this;
		self.genders = [
			{'name' : 'Femenino', 'value' : 'F'},
			{'name' : 'Masculino', 'value' : 'M'},
			{'name' : 'Otro', 'value' : 'O'}
		];

		if (!self.user.gender) self.user.gender = null;
	}

	getClients()
	{
		var self = this;

		this.utils.httpGet(false, false, 'api/client/').then(function(r) {
			if (r) self.clients = r;
		});
	}

	getAllies()
	{
		var self = this;

		this.utils.httpGet(false, false, 'api/ally/').then(function(r) {
			if (r) {
				self.allies = r;

				if (!self.user.ally_id.ally_id) self.user.ally_id.allly_id = null;
			}
		});
	}

	getDepartments()
	{
		var self = this;

		this.utils.httpGet(false, false, 'api/department/').then(function(r) {
			if (r) {
				self.departments = r;

				if (!self.department_id) self.department_id = null;
				if (!self.user.city_id.city_id) self.user.city_id.city_id = null;
			}
		});
	}

	getCities(id)
	{
		var self = this;

		this.utils.httpGet(false, false, 'api/city/show_by_department_id/'+ id).then(function(r) {
			if (r) self.cities = r;
		});
	}

	getIdTypes()
	{
		var self = this;

		this.utils.httpGet(false, false, 'api/id_type/show_by_entity/0').then(function(r) {
			if (r) {
				self.id_types = r;

				if (self.user.id_type_id.length == 0) self.user.id_type_id = null;
			}

			self.session.loadScript(self.session.getCmsUrl() + '/assets/Customjs/inputs.js');
			setTimeout(() => {
				$('input').selectpicker('refresh');
			}, 1);
		});
	}

	getApplications()
	{
		var self = this;

		this.utils.httpGet(true, false, 'api/application/custom/index').then(function(r) {
			if (r) {
				self.applications = r;

				if (self.user_id) self.getRApplications();
			}
		});
	}

	//Obligatorias
	getRApplications()
	{
		var self = this;

		this.utils.httpGet(true, false, 'api/application/show_by_agent/' + self.user_id).then(function(r) {
			if (r) {
				var tmp = [];
				for (var i = 0; i < r['length']; i++) tmp[i] = r[i]['application_id'];

				$('.opt-array').each(function(){
					if (self.utils.inArray($(this).val(), tmp)) $(this).prop('checked', true);
				});

				setTimeout(() => {
					$('input').selectpicker('refresh');
				}, 1);
			}
		});
	}

	confirmPass()
	{
		if (this.user.password && this.user.password != this.c_password) {
			$('#password').parent().addClass('error');
			$('#c_password').parent().addClass('error');

			$('#password').addClass('c_error');
			$('#c_password').addClass('c_error');

			$('#password_error').html('Las contraseñas no coinciden.');
			$('#c_password_error').html('Las contraseñas no coinciden.');

			this.utils.showMsg('Las contraseñas no coinciden.', 'error');
			$('.preloader-content').fadeOut();
			return false;
		}

		return true;
	}

	//Recorro los checkbox seleccionados para saber que apps tendra relacionadas
	setApplications()
	{
		var self = this;

		self.user.application_id = [];

		$('.opt-array').each(function() {
			var id    = $(this).prop('id');
			var tag   = $(this).prop('tagName');
			var value = null;

			if (id) {
				if (tag == 'INPUT') if ($(this).prop('checked')) value = $(this).val();

				if (value) self.user['application_id'].push(value);
			}
		});
	}

	saveUser()
	{
		var self = this;
		$('#main-form').fadeIn();
		$('.date').each(function() { self.user[$(this).prop('id')] = $(this).val(); });
		self.setApplications();

		if (this.confirmPass()) {
			if (this.user_id) {
				this.utils.httpPut(true, true, 'api/user/' + this.user_id, this.user.gAgentUpdate(this.user)).then(function(r) {
					if (r) {
						self.utils.httpPost(true, false, 'api/app_agent/store_multiple/' + self.user.user_id, self.user.gAgentApps(self.user)).then(function(r) {
							self.router.navigate(['agent/show/' + self.user_id]);
						});
					}
				});
			} else {
				this.utils.httpPost(true, true, 'api/user/', this.user.gAgentCreate(this.user)).then(function(r) {
					if (r) self.router.navigate(['agent/show/' + r.user_id]);
				});
			}
		}
	}
}
