import { Component, OnInit }			             from '@angular/core';
import { Router, ActivatedRoute }							 from '@angular/router';
import { SessionService }											 from '../../services/session.service';
import { UtilsService }												 from '../../services/utils.service';
import { Application }												 from '../../models/application';

declare var $;

@Component({
  selector		: 'app-application-form',
  templateUrl	: './form.component.html'
})

export class ApplicationFormComponent implements OnInit 
{
	private application    = new Application();
	private application_id = 0;
	private param					 : any;
	private title					 = 'CREAR CAMPAÑA';
	private clients				 : any;
	private divisions			 : any;
	private r_fields       : any;
	private fields				 : any;
	private allies				 : any;
	private s_allies       : any;
	private agents				 : any;
	private exp						 : false;
	private close					 : false;
	private success				 : any;
	private data					 : any;
	private ss						 : any;
	private name					 : string = '';

  constructor
	(
    private router  : Router,
    private route		: ActivatedRoute,
    private session : SessionService,
		private utils		: UtilsService
	) { }

  ngOnInit() 
	{
		this.param = this.route.params.subscribe(
			params => {
				if (params['id']) {
					this.application_id = params['id'];
					this.title					= 'ACTUALIZAR CAMPAÑA';
				}
			}
		);
	
		$('.date').bootstrapMaterialDatePicker({format: 'YYYY-MM-DD H:mm:ss', time: true});
		this.ss       = sessionStorage;
		this.success  = false;
		this.s_allies = [1];
		this.steps();
		this.getClients();
		this.getAllies();
		this.getDivisions();
		this.getFields();
		//this.utils.fixInputs();
  }
	
	steps()
	{
		var self = this;

		$('.steps').steps({
			enableCancelButton : true,
			labels : {
				next		 : 'Siguiente',
				previous : 'Anterior',
				finish   : 'Guardar',
				cancel   : 'Cancelar'
			},
			onStepChanging : function(e, currentIndex, newIndex) {
				if (currentIndex < newIndex && !self.success) {
					$('#main-form').fadeIn();
					self.setArrays();

					//if (currentIndex < 3 || (currentIndex == 3 && self.application.exp) || (currentIndex == 4 && self.application.close)) {
					if (currentIndex < 3 || (currentIndex == 3 && self.application.exp)) {

						if (self.application_id) {
							self.data = self.application.gUpdate(self.application, self.application_id);
						} else {
							self.data = self.application.gCreate(self.application);
						}

						self.utils.httpStep('application', currentIndex, self.data, false).then(function(r) {
							if (r) { 
								self.success = true;
								$('a[href="#next"]').click();
							}
						});
					} else {
						self.success = true;
					}

					return self.success;
				} else {
					return true;
				}
			},			
			onStepChanged : function(e, currentIndex, priorIndex) {
				$('#main-form').fadeOut();
				self.success = false;
			},
			onFinishing : function(e, currentIndex) {
				$('#main-form').fadeIn();
				self.setArrays();

				if (self.application.close) {
					if (self.application_id) {
						self.data = self.application.gUpdate(self.application, self.application_id);
					} else {
						self.data = self.application.gCreate(self.application);
					}

					self.utils.httpStep('application', currentIndex, self.data, false).then(function(r) {
						if (r) { 
							self.success = true;
							self.saveApplication();
						}
					});
				} else {
					self.success = true;
					self.saveApplication();
				}

				return self.success;
			},
			onCanceled : function(e, currentIndex) {
				self.router.navigate(['application']);
			}
		});
	}

	getApplication()
	{
		var self = this;

		this.utils.httpGet(false, false, 'api/application/' + this.application_id).then(function(r) {
			if (r) {
				self.application.setApplication(r);
				self.name = self.application.name;
	
				for (const d of r['division_id']) $('#division_id[value="' + d.division_id + '"]').prop('checked', true);

				for (const d of r['field_id']) $('#field_id[value="' + d.field_id + '"]').prop('checked', true);
				
				for (const d of r['field_id']) $('#field_id[value="' + d.field_id + '"]').prop('checked', true);

				if (r['exp_m_img']) $('#s_exp').click();

				if (r['close_m_img']) $('#s_close').click();

				setTimeout(() => {
					$('input').selectpicker('refresh');
				}, 1);

				if (r['ally_id'].length > 0) self.loadArrays(r);
				self.utils.fixInputs();
			}
		});
	}

	getClients()
	{
		var self = this;

		this.utils.httpGet(false, false, 'api/client/').then(function(r) {
			if (r) {
				self.clients = r; 
				
				if (!self.application.client_id.client_id) self.application.client_id.client_id = null; 
			}
		});
	}

	getAllies()
	{
		var self = this;
		var path = (self.application_id > 0) ? '' : 'list_by_state/1'; 

		this.utils.httpGet(false, false, 'api/ally/' + path).then(function(r) {
			if (r) {
				self.allies = r; 
				setTimeout(() => {
					$('input').selectpicker('refresh');
				}, 1);
			}
		});
	}

	getDivisions()
	{
		var self = this;

		this.utils.httpGet(false, false, 'api/division/').then(function(r) {
			if (r) self.divisions = r; 
		});
	}

	getFields()
	{
		var self = this;

		this.utils.httpGet(false, false, 'api/field/show_by_required/0').then(function(r) {
			if (r) { 
				self.fields = r; 

				if (self.application_id) self.getApplication();
			}
			
			self.session.loadScript(self.session.getCmsUrl() + '/assets/Customjs/inputs.js');
		});

		this.utils.httpGet(true, false, 'api/field/show_by_required/1').then(function(r) {
			if (r) { 
				self.r_fields = r; 

				setTimeout(() => {
					$('input').selectpicker('refresh');
				}, 1);
			}
		});
	}
 
	//Obtener agentes al seleccionar ally
	getAgents(e)
	{
		var self  = this;
		var path  = (self.application_id > 0) ? '' : '/1'; 
		var ac    = $(e).parent().parent().parent().find('table');
		var value = $(e).find(':selected').prop('value');
		ac.find('tbody').html('');

		this.utils.httpGet(false, false, 'api/user/custom/agent_by_ally/' + value + path).then(function(r) {
			if (r) {
				for (var i = 0; i < r['length']; i++) {
					ac.find('tbody').append(`
							<tr>
								<td>
									<input id="agent_id" class="chk-col-green opt-array agent_` + r[i]['user_id']+ `" value="` + r[i]['user_id'] + `" type="checkbox" />
									<label></label>
								</td>
								<td>` + r[i]['id_type'] + ` ` + r[i]['id_number'] + `</td>
								<td>` + r[i]['name'] + ` ` + r[i]['last_name'] + `</td>
							</tr>
					`);
				}
			} 
		});
	}

	setPath()
	{
			this.application.path = $.trim((this.application.name)).normalize('NFD')
																														 .replace(/[\u0300-\u036f]/g, '')
																														 .replace(/_/g, ' ')
																														 .replace(/  +/g, ' ')
																														 .replace(/ /g, '-')
																														 .replace(/_ +/g, '-')
																														 .toLowerCase();

			$('#path').val(this.application.path);

			setTimeout(() => {
				$('input').selectpicker('refresh');
			}, 1);
	}

	//Obtener valores de checkboxes y dates para poder guardar
	setArrays()
	{
		var self = this;

		self.application.division_id = [];
		self.application.field_id		 = [];
		self.application.ally_id     = [];
		self.application.agent_id    = [];

		$('.opt-array').each(function() {
			var id    = $(this).prop('id');
			var tag   = $(this).prop('tagName');
			var value = null;

			if (id) {
				if (tag == 'INPUT') if ($(this).prop('checked')) value = $(this).val();

				if (tag == 'SELECT') value = $(this).prop('value');

				if (value && value > 0) self.application[id].push(value); 
			}
		});
		
		$('.date').each(function() { self.application[$(this).prop('id')] = $(this).val(); });
	}

	//Cuando voy a ditar una app, chuleo checkboxes
	loadArrays(r)
	{
		var self      = this;
		var count     = 0;
		self.s_allies = [];

		for (const ally of r['ally_id']) self.s_allies.push(self.s_allies.length +1);

		setTimeout(function() {
			for (const ally of r['ally_id']) {
				count = count + 1;
				$('.ally_' + count).find('option[value=' + ally.ally_id + ']').prop('selected', true);		
			}	 
		}, 1);

		setTimeout(function() {
			count = 0;
			for (const ally of r['ally_id']) {
				count = count + 1;
				self.getAgents('.ally_' + count);
			}	 
		}, 1);

		setTimeout(function() {
			for (const agent of r['agents']['user_id']) {
				$('.agent_' + agent).prop('checked', true);
			}
		}, 2000);
	}

	//Agregar elminiar ally
	mAlly(action, index = null)
	{
		if (action == 'add') {
			this.s_allies.push(this.s_allies.length + 1);
		} else {
			console.log(this.s_allies.length);
			if (this.s_allies.length > 1) {
				this.s_allies.splice(this.s_allies.indexOf(index), 1);
			} else {
				$('#ally_id option[value="0"]').prop('selected', true); 	
				$('#ally_id').parent().parent().parent().find('tbody').html('');
			}
		}
	}

	showExtra(e)
	{
		this.application[e] = !this.application[e];			
	}

	saveApplication()
	{
		var self = this;

		$('#main-form').fadeIn();

		this.setArrays();

		if (this.application_id) {
			if (self.name != self.application.name) self.utils.ccHttpPut(false, false, 'api/api_keys/' + self.application_id, {'name' : self.application.name}).then(function(r2) {});

			self.utils.httpPost(true, true, 'api/application/custom/update', self.application.gUpdate(self.application, self.application_id), true).then(function(r) {
				if (r) self.router.navigate(['application/show/' + self.application_id]);
			});
		} else {
			this.utils.httpPost(true, true, 'api/application/custom', this.application.gCreate(this.application), true).then(function(r) {
				if (r) self.router.navigate(['application/show/' + r.application_id]);
			});
		}
	}
	
	selectFile(event) 
	{
		var self						 = this;
		const inputValue		 = event.target;
		const files					 = inputValue.files;
		const id						 = $('#' + inputValue.id).data('id');
		const sid						 = $('#' + inputValue.id).data('sid');
		this.application[id] = inputValue.files[0];
		
		for (let i = 0, f; f = files[i]; i++) {
			//if (!f.type.match('image.*')) continue;
			const reader = new FileReader();
			reader.onload = (function(theFile) {
				return function(e) {
					if (theFile.type.match('image.*')) {
						$('.' + id).html(['<div><img class="thumb" src="', e.target.result, '" title="', theFile.name, '"/></div>'].join(''));
					} else {
						$('.' + id).html(['<div><i class="material-icons pdf-thumb thumb">picture_as_pdf</i></div>'].join(''));
					}
					
					self.application[sid] = 'true';
				};
			})(f);
		
			reader.readAsDataURL(f);
		}
	}

	//Chuleo y deschuleo campos obligatorios no obligatorios
	checkField(event) 
	{
		$('.opt-array').each(function(e) {
			if ($(this).prop('name') == event.target.name && $(this).prop('value') != event.target.value) {
				$(this).prop('checked', false);
			}	
		});
	}
}
