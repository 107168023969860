import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from '../../services/session.service';
import { UtilsService } from '../../services/utils.service';
import { User } from '../../models/user';

declare var $;

@Component({
    selector: 'app-user-form',
    templateUrl: './form.component.html'
})

export class UserFormComponent implements OnInit {
    private user = new User();
    private user_id = 0;
    private c_password = '';
    private department_id = 0;
    private param: any;
    private title = 'CREAR USUARIO';
    private roles: any;
    private clients: any;
    private allies: any;
    private departments: any;
    private cities: any;
    private id_types: any;
    private sa: boolean;
    private genders: any;

    constructor
    (
        private htitle: Title,
        private router: Router,
        private route: ActivatedRoute,
        private session: SessionService,
        private utils: UtilsService
    ) { }

    ngOnInit() {
        this.param = this.route.params.subscribe(
            params => {
                if (params['id']) {
                    this.user_id = params['id'];
                    this.title	 = 'ACTUALIZAR USUARIO';
                    this.getUser();
                }
            }
        );

        this.htitle.setTitle('Formularios Únicos - Perfil');
        this.sa = false;
        $('.date').bootstrapMaterialDatePicker({format: 'YYYY-MM-DD', time: false});
        this.genders = [{'name' : 'Femenino', 'value' : 'F'}, {'name' : 'Masculino', 'value' : 'M'}];
        this.getURoles();
        this.getClients();
        this.getDepartments();
        this.getIdTypes();
    }

    getUser() {
        const self = this;

        this.utils.httpGet(false, false, 'api/user/' + this.user_id).then(function(r) {
            if (r) {
                self.user.setUser(r);
                self.department_id = r['department_id'];
                self.getCities(self.department_id);
                self.user.city_id.city_id = r['city_id'];
                if (self.user.user_rol_id.user_rol_id === 1) { self.sa = true; }
            }
        });
    }

    getURoles() {
        const self = this;

        this.utils.httpGet(false, false, 'api/user_rol/').then(function(r) {
            if (r) {
                self.roles = r;
                if (!self.user.user_rol_id.user_rol_id) { self.user.user_rol_id.user_rol_id = null; }
            }
        });
    }

    getClients() {
        const self = this;

        this.utils.httpGet(false, false, 'api/client/').then(function(r) {
            if (r) {
                self.clients = r;
                if (!self.user.client_id.client_id) { self.user.client_id.client_id = null; }
            }
        });
    }

    getDepartments() {
        const self = this;

        this.utils.httpGet(false, false, 'api/department/').then(function(r) {
            if (r) {
                self.departments = r;
                if (!self.department_id) { self.department_id = null; }
                if (!self.user.city_id.city_id) { self.user.city_id.city_id = null; }
            }
        });
    }

    getCities(id) {
        const self = this;

        this.utils.httpGet(false, false, 'api/city/show_by_department_id/' + id).then(function(r) {
            if (r) { self.cities = r; }
        });
    }

    getIdTypes() {
        const self = this;

        this.utils.httpGet(true, false, 'api/id_type/show_by_entity/0').then(function(r) {
            if (r) {
                self.id_types = r;
                if (self.user.id_type_id.length === 0) { self.user.id_type_id = null; }
            }

            self.session.loadScript(self.session.getCmsUrl() + '/assets/Customjs/inputs.js');
        });
    }

    isSuperAdmin(e) {
        this.sa = ($(e.target).find(':selected').prop('value') === '1: 1') ? true : false;
    }

    confirmPass() {
        if (this.user.password && this.user.password !== this.c_password) {
            $('#password').parent().addClass('error');
            $('#c_password').parent().addClass('error');

            $('#password').addClass('c_error');
            $('#c_password').addClass('c_error');

            $('#password_error').html('Las contraseñas no coinciden.');
            $('#c_password_error').html('Las contraseñas no coinciden.');

            this.utils.showMsg('Las contraseñas no coinciden.', 'error');
            $('.preloader-content').fadeOut();
            return false;
        }

        return true;
    }

    saveUser() {
        const self = this;
        $('#main-form').fadeIn();
        $('.date').each(function() { self.user[$(this).prop('id')] = $(this).val(); });

        if (this.confirmPass()) {
            if (this.user_id) {
                this.utils.httpPut(true, true, 'api/user/' + this.user_id, this.user.gUpdate(this.user)).then(function(r) {
                    if (r) { self.router.navigate(['user/show/' + self.user_id]); }
                });
            } else {
                this.utils.httpPost(true, true, 'api/user/', this.user.gCreate(this.user)).then(function(r) {
                    if (r) { self.router.navigate(['user/show/' + r.user_id]); }
                });
            }
        }
    }
}
