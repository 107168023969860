import { Directive, ElementRef, Renderer2, Input } from '@angular/core';

@Directive({
	selector : '[tooltip]'
})

export class TooltipDirective 
{
	private tooltipHTML   : string;
	@Input() tooltipTITLE : string;

	constructor
	(
		private el : ElementRef,
		private re : Renderer2
	)
	{
		this.re.addClass(this.el.nativeElement, 'a-tooltip');
	}

	ngAfterViewInit()
	{
		this.tooltipHTML = '<span class="c-tooltip-wrapper"><span class="c-tooltip">' + this.tooltipTITLE + '</span></span>';

		this.el.nativeElement.insertAdjacentHTML('beforeend', this.tooltipHTML);
	}
}
