import { Component, OnInit } from '@angular/core';
import { PermissionService } from 'angular2-permission/dist';
import { Router } from '@angular/router';
import { SessionService } from '../services/session.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: []
})

export class HomeComponent implements OnInit 
{
	private user;

  constructor() {}

  ngOnInit() 
	{
		this.user = JSON.parse(sessionStorage.getItem('user'));
  }
}
