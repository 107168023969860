import { Component, OnInit } from '@angular/core';
import { SessionService }    from '../../services/session.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit 
{
  constructor
	(
    private sessionService: SessionService,
	) { }

  ngOnInit() 
	{
		this.sessionService.isLoggedIn();
  }
}
