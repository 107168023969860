import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute }  from '@angular/router';
import { SessionService }					 from '../../services/session.service';
import { UtilsService }            from '../../services/utils.service';
import { Application }						 from '../../models/application';

declare var $;

@Component({
  selector: 'app-application-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})

export class ApplicationAnalyticsComponent implements OnInit 
{
	private application    = new Application();
	private application_id = 0;
	private param          : any;

  constructor
	(
    private router  : Router,
    private route		: ActivatedRoute,
    private session : SessionService,
		private utils		: UtilsService
	) { }

  ngOnInit() 
	{
  	this.param = this.route.params.subscribe(
			params => {
				if (params['id']) {
					this.application_id = params['id'];
					this.getApplication();
				}
			}
		);
	}

	getApplication()
	{
		var self = this;

		this.utils.httpGet(true, false, 'api/application/' + this.application_id).then(function(r) {
			if (r) {
				self.application.setApplication(r);
				self.session.loadScript(self.session.getCmsUrl() + '/assets/Customjs/inputs.js');
	
				setTimeout(() => {
					$('input').selectpicker('refresh');
					$('#main-form').fadeOut();
				}, 1);
			}
		});
	}

	saveApplication()
	{
		var self = this;

		this.utils.httpPut(true, true, 'api/application/custom/analytics/' + this.application_id, this.application.gAnalytics(this.application.analytics)).then(function(r) {
			if (r) self.router.navigate(['application/show/' + self.application_id]);
		});
	}
}
