import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UtilsService } from '../../services/utils.service';
import { User } from '../../models/user';

declare var $;

@Component({
    selector: 'app-agent-show',
    templateUrl: './show.component.html'
})

export class AgentShowComponent implements OnInit {

    private user = new User();
    private user_id = 0;
    private param: any;
    private applications: any = [];
    private regs: any = [];
    private genders: any = [];

    constructor (
        private router: Router,
        private route: ActivatedRoute,
        private utils: UtilsService
    ) { }

    ngOnInit() {
        this.param = this.route.params.subscribe(
            params => {
                this.user_id = params['id'];
            }
        );

        this.genders['M'] = 'Masculino';
        this.genders['F'] = 'Femenino';
        this.genders['O'] = 'Otro';

        this.getApplications();
        this.getUser();
    }

    getUser() {
        const self = this;

        this.utils.httpGet(false, false, 'api/user/custom/agent/' + this.user_id).then(function(r) {
            $('#s-form').fadeIn();
            if (r) self.user.setUser(r);
        });
    }

    getApplications() {
        const self = this;

        this.utils.ccHttpGet(false, false, 'api/cuf/regs_by_agent_total/' + this.user_id).then(function(r) {
            if (r) {
                self.regs = r;
                self.regs.length = Object.keys(self.regs).length;
            }

            self.utils.httpGet(true, false, 'api/application/show_by_agent/' + self.user_id).then(function(r2) {
                if (r2) self.applications = r2;
            });
        });
    }
}
