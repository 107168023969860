import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from '../services/session.service';
import { UtilsService } from '../services/utils.service';
import { Application } from '../models/application';

declare var $;
declare var $JExcel;
declare var ClipboardJS;
declare var clipboard;





function showAchievement (title, message, icon, type) {
    //alerta title, message, icon, type(dander, warning, info, success)
    $.notify({
        // options
        message: message + '.',
        icon: '',
        title: title + '<br>', 
    },{
        // settings
        type: type,
        placement: {
            from: "top",
            align: "right"
        },
        offset: 20,
        spacing: 10,
        z_index: 1060,
        showProgressbar: true,
        animate: {
            enter: 'animated fadeInDownBig',
            exit: 'animated fadeOutUpBig'
        },
        template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
		'<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
		'<span data-notify="icon"> <i class="material-icons icon-alert">' + icon + '</i></span> ' +
		'<span data-notify="title">{1}</span> ' +
		'<span data-notify="message">{2}</span>' +
		'<div class="progress" data-notify="progressbar">' +
			'<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
		'</div>' +
	    '</div>' 
    });

}



@Component({
    selector: 'app-application-basic',
    templateUrl: './applicationBasic.component.html'
})

export class ApplicationBasicComponent implements OnInit {

    public titulo = '';
    private applications = [];
    private application  = new Application();
    private param: any;

    constructor(
        private title: Title,
        private router: Router,
        private route: ActivatedRoute,
        private session: SessionService,
        private utils: UtilsService,
    ) { }

    ngOnInit() {
        this.title.setTitle('Campañas Cheil Connect');
        this.titulo = 'Campañas Cheil Connect';
        this.getApplications();
        this.createExcelTemplate;
        var getUrlUploadimages =  this.session.url; 
        var getUrlUploadimagesToken =  this.session; 
        this.getDropZone(getUrlUploadimages, getUrlUploadimagesToken);
        
   
    }


 


    getApplications() {
        const self = this;

        this.utils.httpGet(true, false, 'api/application/custom/indexBasic').then(function(r) {
            if (r) {
                Object.keys(r).map(function(i) { self.applications.push(r[i]); });
                self.session.loadScript(self.session.getCmsUrl() + '/assets/Customjs/pages/tables/jquery-datatable.js');
                
            }
        });

        
    }

    getDropZone(getUrlUploadimages, getUrlUploadimagesToken){
      
        $(document).on("change", ".holder_default", function () {      
            var inputformData = $(this).find("input")[0].files[0];
            if(inputformData.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"){
                var getApplication_id = $(this).parent().parent().parent().parent().attr("idcampaign");
                var getApplication_Token = $(this).parent().parent().parent().parent().attr("tokennumber");
                var inputform = $(this).find("input")[0].files[0].name;
                var inputformSend = $(this).find("input")[0].files[0];
                //console.log(inputformSend);
                var data = new FormData();
                data.append('application_id', getApplication_id);        
                data.append('upload_file', inputformSend);
                $(this).find("p").text(inputform +" se ha seleccionado.");

                //click action upload file

                $(this).on("click", ".upload-now", function(){
                    $('.preloader-content').fadeIn();

                    //console.log(getUrlUploadimagesToken);

                    fetch(getUrlUploadimages+'api/application/custom/uploadUsersBasic', {
                        method: 'POST',
                        headers: {
                          'Authorization': "Bearer " +sessionStorage.getItem('token')
                        },
                        body: data
                      }).then(response => {                    
                        if (response.status >= 200 && response.status < 300) {
                          return response;
                        } else {
                          var error = new Error(response.statusText)
                          throw error
                        }
                      }).then(response => {
                        return response.json()
                      }).then(data => {
                        //console.log(data);
                        showAchievement("Mensaje", data.message, "attach_file", "success");
                        $(".holder_default button").addClass("hide");
                        $(".holder_default .drag-icon-cph i").addClass("bighand flipInX");
                         $('.preloader-content').fadeOut();
                         $(".initial-text-upload").text(data.message);
                         $("body").attr("idcampaign", "");
                         $('#sendDataUsers').trigger("reset");
                         setTimeout(function(){
                             location.reload();
                         }, 4000);
                        //$('#modalFileUpload').modal('hide');
                      })

                })

                

                  //end click button
            }else{
               showAchievement("Alerta", "El archivo no es permitido. sólo se admite formato .xlsx", "error_outline", "danger");       
            }         
          });

 
    }


    createExcelTemplate(client_id : any, field_id : any, application_id: any)
	{
        var self = this;
        
        var self2 = this.applications;
        var getidTable = $("#modalFileUpload").attr("idcampaign");
        var getTokenTable = $("#modalFileUpload").attr("tokennumber");
        var applicationsQuantity = this.applications.length;

		this.utils.httpGet(false, false, 'api/application/' +getidTable).then(function(r) {
			if (r) {
                self.application.setApplication(r); 
                //console.log(r); //Datos de la campaña

                var excel = $JExcel.new();                    // Create an Excel with system default font
                var excel = $JExcel.new("Arial 10 #333333");  // Default font is Arial 10 in RGB #333
                excel.set( {sheet:0,value:"Datos para la campaña con ID "+ getidTable } );
                var evenRow=excel.addStyle( { border: "none,none,none,thin #333333"});        
                var oddRow=excel.addStyle ( { fill: "#ECECEC" ,border: "none,none,none,thin #333333"}); 
                for (var i=1;i<2;i++) excel.set({row:i,style: i%2==0 ? evenRow: oddRow  });  
                excel.set({row:3,value: 30  }); 
                //var headers = ["application_id","key"];
                var headers = [];

                for (const d of r['field_id']) {
                    //console.log(d.name); //campos de la campaña                  
                    var nameQuantity = r['field_id'].length;
                    var nameValueField = d.name;
                    var substring = "extra";
                    var nameextra = nameValueField.includes(substring)
                    if(nameextra == true){
                        //console.log('"'+headers+'"');
                        headers.push('"'+nameValueField+'"');
                    }else{
                        //console.log(headers);
                        headers.push(nameValueField);
                    }
                    //console.log(nameextra);
                    //headers.push(nameValueField);
                    //console.log(headers);
                }
                    
                var formatHeader=excel.addStyle ( {
                    border: "none,none,none,thin #333333",font: "Calibri 12 #0000AA B"}
                );                                                         
                
                for (var i=0;i<headers.length;i++){              // Loop headers
                    excel.set(0,i,0,headers[i],formatHeader);    // Set CELL header text & header format
                    excel.set(0,i,undefined,"auto");             // Set COLUMN width to auto 
                }
                                                                                                
                            
                for (var i=1;i<=1;i++){                                    // Generate 50 rows
                    //excel.set(0,0,i,getidTable);                    // This is id campaign
                    //excel.set(0,1,i,getTokenTable);                   // get token campaign
                    excel.set(0,0,i,"email@email.com");
                    //excel.set(0,3,i,"Some other text 2");
                    //excel.set(0,4,i,"Some other text 3");                    // Some other text
                }
                
                //excel.set(0,1,undefined,30);                               // Set COLUMN B to 30 chars width
                //excel.set(0,3,undefined,30);                               // Set COLUMN D to 20 chars width
                excel.generate("SampleData.xlsx");
                showAchievement("Mensaje", "El archivo .xlsx fue generado", "find_in_page", "success");

				
			}
		});


    }


    //create json input
    createJsonTemplate(client_id : any, field_id : any, application_id: any)
	{
        var self = this;
        var self2 = this.applications;
        var getidTable = parseInt($("#modalFileUpload").attr("idcampaign"));
        var getTokenTable = $("#modalFileUpload").attr("tokennumber");
        var applicationsQuantity = this.applications.length;

		this.utils.httpGet(false, false, 'api/application/' +getidTable).then(function(r) {
			if (r) {
                self.application.setApplication(r); 
                //console.log(r); //Datos de la campaña
                //var field_id = [];
                var field_idName = [];
                var field_idNameValue = [];
                var field_Required = [];
                var field_Type = [];

                for (const d of r['field_id']) {
                    var nameValueField = d.name;
                    var required = d.required;
                    var type = d.type;
                    var nameValueFieldPlaceholder = d.placeholder;
                    field_idName.push(nameValueField);
                    field_idNameValue.push(nameValueFieldPlaceholder);
                    field_Required.push(required);
                    field_Type.push(type);
                    //console.log(field_idNameValue);
                }

                var items = "";
                
                for (var x=0;x<field_idNameValue.length;x++){

                    if(field_Required[x] == true){
                        field_Required[x] = "    //El campo es requerido.";
                    }

                    if(field_Required[x] == false){
                        field_Required[x] = "    //El campo es opcional.";
                    }

                    //console.log("index: "+ x + " "+field_idNameValue[x] + " la propiedad es:"+ field_idName[x]+ " El campo es requerido: "+ field_Required[x]+ " y su tipo es: "+ field_Type[x]);
                    items += "<div id='campo-"+x+"' class='hljs-attr'>"+field_idName[x]+ ':<span class="hljs-name">"'+field_idNameValue[x]+'",</span><span class="hljs-comment">'+field_Required[x]+'</span>'+"</div>"
                }
                       
                $("#outputJson").html("headers: {<div class='hljs-attr'>'Content-Type:<span class='hljs-name'>'application/json'</span>,</div><div class='hljs-attr'>'x-authorization':<span class='hljs-name'>'"+getTokenTable+"'</span><span class='hljs-comment'>    //El campo es requerido.</span></div>}<br>params: {"+items+"<div class='hljs-attr'>application_id:<span class='hljs-name'>"+getidTable+"</span><span class='hljs-comment'>    //El campo es requerido.</span></div>}");
                $(".output-json-container").removeClass("hide");
                showAchievement("Mensaje", "JSON generado", "code", "success");
                                                                                     
			}
		});


    }
    //end json input

 
    
   
}
