import { Injectable }			from '@angular/core';
import {
    HttpClient,
    HttpHeaders,
    HttpParams
}									from '@angular/common/http';
import { SessionService }	from '../services/session.service';

declare var $;
declare var jQuery;
declare var swal;



@Injectable()
export class UtilsService {
    public response: any;

    constructor (
        public http: HttpClient,
        public sessionService: SessionService,
    ) { }

    showMsg(msg: string, type: string) {
        if (window.navigator.userAgent.indexOf('MSIE ') > 0 || window.navigator.userAgent.indexOf('Trident/') > 0 || window.navigator.appName == 'Microsft Internet Explorer') {
            swal('AVISO', msg, type);
        } else {
            alert(msg);
        }
    }

    showAchievement (title, message, icon, type) {
        //alerta title, message, icon, type(dander, warning, info, success)
        $.notify({
            // options
            message: message + '.',
            icon: '',
            title: title + '<br>', 
        },{
            // settings
            type: type,
            placement: {
                from: "top",
                align: "right"
            },
            offset: 20,
            spacing: 10,
            z_index: 1060,
            showProgressbar: true,
            animate: {
                enter: 'animated fadeInDownBig',
                exit: 'animated fadeOutUpBig'
            },
            template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0}" role="alert">' +
            '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
            '<span data-notify="icon"> <i class="material-icons icon-alert">' + icon + '</i></span> ' +
            '<span data-notify="title">{1}</span> ' +
            '<span data-notify="message">{2}</span>' +
            '<div class="progress" data-notify="progressbar">' +
                '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
            '</div>' +
            '</div>' 
        });
    
    }

    showErrors(list) {
        //this.showMsg('Algunos campos son requeridos o  tienen un formato inválido.', 'error');
        console.log(list);
        this.showAchievement("Alerta", list, "error_outline", "danger");

        $('input, select, textarea').each(function() {
            const key = $(this).attr('id');

            if (list[key]) {
                $('#' + key + '_error').html(list[key]);
                $('#' + key).parent().addClass('error');
                $('#' + key).addClass('c_error');
            } else {
                $('#' + key + '_error').html('');
                $('#' + key).parent().removeClass('error');
                $('#' + key).removeClass('c_error');
            }
        });
    }

    cleanErrors() {
        $('body, html').find('label.error').html('');
    }

    httpGet(hLoader, msg, path) {
        return new Promise(
            resolve => {
                this.http.get(
                    this.sessionService.getUrl() + path,
                    {
                        headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                            .set('Authorization', 'Bearer ' + sessionStorage.getItem('token'))
                    }
                ).subscribe(
                    data => {
                        if (hLoader) { $('.preloader-content').fadeOut(); }
                        resolve(data['data']);
                    },
                    err => {
                        if (hLoader) { $('.preloader-content').fadeOut(); }
                        if (msg) { this.showErrors(err.error.list); }
                        resolve(false);
                    }
                );
            }
        );
    }

    httpPost(hLoader, msg, path, params, file = null): Promise<any> {
        return new Promise(
            resolve => {
                let headers = new HttpHeaders().set('Authorization', 'Bearer ' + sessionStorage.getItem('token'));
                if (!file) {
                    headers = new HttpHeaders().set('Content-Type', 'application/json')
                        .set('Authorization', 'Bearer ' + sessionStorage.getItem('token'));
                }

                this.http.post(
                    this.sessionService.getUrl() + path,
                    params,
                    {
                        headers : headers
                    }
                ).subscribe(
                    data => {
                        if (hLoader) { $('.preloader-content').fadeOut(); }
                        if (msg) { 
                            //this.showMsg('El registro se guardó exitosamente.', 'success');
                            this.showAchievement("Mensaje", "El registro se guardó exitosamente", "check_circle", "success");

                         }
                        resolve(data['data']);
                    },
                    err => {
                        console.log(err);
                        if (hLoader) { $('.preloader-content').fadeOut(); }
                        if (err.error.hasOwnProperty('list')) {
                            this.showErrors(err.error.list);
                        } else {
                            this.showErrors(err.error.message);
                        }
                        resolve(false);
                    }
                );
            }
        );
    }

    httpPut(hLoader, msg, path, params): Promise<any> {
        return new Promise(
            resolve => {
                this.http.put(
                    this.sessionService.getUrl() + path,
                    params,
                    {
                        headers: new HttpHeaders().set('Content-Type', 'application/json')
                            .set('Authorization', 'Bearer ' + sessionStorage.getItem('token'))
                    }
                ).subscribe(
                    data => {
                        if (hLoader) { $('.preloader-content').fadeOut(); }
                        if (msg) { 
                            //this.showMsg('El registro se actualizó exitosamente.', 'success');
                            this.showAchievement("Mensaje", "El registro se actualizo exitosamente", "check_circle", "success");

                         }
                        resolve(data['data']);
                    },
                    err => {
                        if (hLoader) { $('.preloader-content').fadeOut(); }
                        this.showErrors(err.error.list);
                        resolve(false);
                    }
                );
            }
        );
    }

    httpStep(model, step, params, file): Promise<any> {
        const self = this;

        return new Promise(
            resolve => {
                const headers = new HttpHeaders().set('Authorization', 'Bearer ' + sessionStorage.getItem('token'));

                this.http.post(
                    this.sessionService.getUrl() + 'api/' + model + '/validate_step/' + step,
                    params,
                    {
                        headers : headers
                    }
                ).subscribe(
                    data => {
                        $('.preloader-content').fadeOut();
                        this.cleanErrors();
                        resolve(true);
                    },
                    err => {
                        $('.preloader-content').fadeOut();
                        this.showErrors(err.error.list);
                        resolve(false);
                    }
                );
            }
        );
    }

    ccHttpPost(hLoader, msg, path, params, file = null): Promise<any> {
        return new Promise(
            resolve => {
                let headers = new HttpHeaders().set('x-Authorization', '3c7288c1a104694404b07d4e4244a8b8991ca335');
                if (!file) {
                    headers = new HttpHeaders().set('Content-Type', 'application/json')
                        .set('x-Authorization', '3c7288c1a104694404b07d4e4244a8b8991ca335');
                }

                this.http.post(
                    this.sessionService.getCCUrl() + path,
                    params,
                    {
                        headers : headers
                    }
                ).subscribe(
                    data => {
                        if (hLoader) { $('.preloader-content').fadeOut(); }
                        if (msg) { 
                            //this.showMsg('El registro se guardó exitosamente.', 'success'); 
                            this.showAchievement("Mensaje", "El registro se guardó exitosamente", "check_circle", "success");

                        }
                        resolve(data['data']);
                    },
                    err => {
                        if (hLoader) { $('.preloader-content').fadeOut(); }
                        if (msg) { this.showErrors(err.error.list); }
                        resolve(false);
                    }
                );
            }
        );
    }

    ccHttpGet(hLoader, msg, path) {
        return new Promise(
            resolve => {
                this.http.get(
                    this.sessionService.getCCUrl() + path,
                    {
                        headers: new HttpHeaders().set('x-Authorization', '3c7288c1a104694404b07d4e4244a8b8991ca335')
                    }
                ).subscribe(
                    data => {
                        if (hLoader) { $('.preloader-content').fadeOut(); }
                        resolve(data['data']);
                    },
                    err => {
                        if (hLoader) { $('.preloader-content').fadeOut(); }
                        if (msg) { this.showErrors(err.error.list); }
                        resolve(false);
                    }
                );
            }
        );
    }

    ccHttpPut(hLoader, msg, path, params): Promise<any> {
        return new Promise(
            resolve => {
                this.http.put(
                    this.sessionService.getCCUrl() + path,
                    params,
                    {
                        headers: new HttpHeaders().set('x-Authorization', '3c7288c1a104694404b07d4e4244a8b8991ca335')
                    }
                ).subscribe(
                    data => {
                        if (hLoader) { $('.preloader-content').fadeOut(); }
                        if (msg) { 
                            //this.showMsg('El registro se actualizó exitosamente.', 'success'); 
                            this.showAchievement("Mensaje", "El registro se actualizó exitosamente", "check_circle", "success");

                        }
                        resolve(data['data']);
                    },
                    err => {
                        if (hLoader) { $('.preloader-content').fadeOut(); }
                        this.showErrors(err.error.list);
                        resolve(false);
                    }
                );
            }
        );
    }

    inArray(needle, haystack) {
        const length = haystack.length;
        for (let i = 0; i < length; i++) { if (haystack[i] === needle) { return true; } }
        return false;
    }

    fixInputs() {
        /*$('input, textarea').focusout(function() {
         if ($(this).val().length > 0) {
         $(this).parent().find('label').css({'top': '-10px', 'font-size': '12px'});
         } else {
         $(this).parent().find('label').css({'top': '10px', 'font-size': '14px'});
         }
         });*/

        $('input, textarea').each(function() {
            $(this).parent().find('label').css({'top': '-10px', 'font-size': '12px'});
        });

        $('input, textarea').focusin(function() {
            $(this).parent().find('label').css({'top': '-10px', 'font-size': '12px'});
        });
    }
}
