import { Component, OnInit }			 from '@angular/core';
import { Title }									 from '@angular/platform-browser';
import { Router, ActivatedRoute }  from '@angular/router';
import { SessionService }					 from '../services/session.service';
import { UtilsService }            from '../services/utils.service';
import { Application }						 from '../models/application';

declare var $;

@Component({
	selector		: 'app-application',
	templateUrl	: './application.component.html'
})

export class ApplicationComponent implements OnInit
{
	private applications = [];
	private application  = new Application();
	private param: any;

	constructor(
		private title: Title,
		private router: Router,
		private route: ActivatedRoute,
		private session: SessionService,
		private utils: UtilsService
	) { }

	ngOnInit()
	{
		this.title.setTitle('Formularios Únicos - Campañas');
		this.getApplications();
	}

	getApplications()
	{
		var self = this;

		this.utils.httpGet(true, false, 'api/application').then(function(r) {
			if (r) {
				Object.keys(r).map(function(i) { self.applications.push(r[i]); });
				console.log(r);
				self.session.loadScript(self.session.getCmsUrl() + '/assets/Customjs/pages/tables/jquery-datatable.js');
			}
		});
	}
}
