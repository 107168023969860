import { Component, OnInit }			 from '@angular/core';
import { Title }									 from '@angular/platform-browser';
import { Router, ActivatedRoute }  from '@angular/router';
import { SessionService }					 from '../services/session.service';
import { UtilsService }            from '../services/utils.service';
import { Ally }										 from '../models/ally';

declare var $;

@Component({
  selector		: 'app-ally',
  templateUrl : './ally.component.html'
})

export class AllyComponent implements OnInit 
{
	private allies = [];
	private ally   = new Ally();
	private param  : any;

  constructor
	(
		private title   : Title,
    private router  : Router,
    private route		: ActivatedRoute,
    private session : SessionService,
		private utils		: UtilsService
	) { }

  ngOnInit() 
	{
		this.title.setTitle('Formularios Únicos - Empresas Aliadas');
		this.getAllies();
  }

	getAllies() 
	{
	  var self = this;

		this.utils.httpGet(true, false, 'api/ally').then(function(r) {
			if (r) { 
				Object.keys(r).map(function(i) { self.allies.push(r[i]); });
				self.session.loadScript(self.session.getCmsUrl() + '/assets/Customjs/pages/tables/jquery-datatable.js');
			}
		});
	}
}
