import { Component, OnInit }			 from '@angular/core';
import { Router, ActivatedRoute }  from '@angular/router';
import { SessionService }					 from '../../services/session.service';
import { UtilsService }            from '../../services/utils.service';
import { User }										 from '../../models/user';

declare var $;

@Component({
  selector		: 'app-login-recover',
  templateUrl	: './recover.component.html',
  styleUrls		: ['./recover.component.scss']
})

export class LoginRecoverComponent implements OnInit 
{
	private token		  : string;
	private param		  : any;
	private user		  = new User();
	private password  : string;
	private c_password  : string;
	private email 	  : string;
	private id_number : string;

  constructor
	(
    private sessionService : SessionService,
    private router         : Router,
    private route					 : ActivatedRoute,
		private utils				   : UtilsService,
	){ }

  ngOnInit() 
	{
		this.param = this.route.params.subscribe(
			params => {
				if (params['token']) {
					this.token = params['token'];
				} 
			}
		);
  }
	
	confirmPass()
	{
		if (!this.password || (this.password && this.password != this.c_password)) {
			$('#password').parent().addClass('error');
			$('#c_password').parent().addClass('error');

			$('#password').addClass('c_error');
			$('#c_password').addClass('c_error');

			$('#password_error').html('Password doesn\'t match.');
			$('#c_password_error').html('Password doesn\'t match.');

			this.utils.showMsg('Passwords don\'t match', 'error');
			return false;
		}

		return true;
	}

	saveUser()
	{
		var self = this;

		if (this.token) {
			if (this.confirmPass()) {
				this.utils.httpPost(true, true, 'api/save_password', this.user.gPassword(this.password)).then(function(r) {
					//if (r) self.router.navigate(['login']);
				});
			}
		} else {
			this.utils.httpPut(true, false, 'api/reset_password', this.user.gRecover(this.email, this.id_number)).then(function(r) {
				if (r) {
					self.utils.showMsg('Un correo electrónico ha sido enviado con los pasos a seguir para ingresar nuevamente.', 'success');
					self.router.navigate(['login']);
				}
			});
		}
	}
}
