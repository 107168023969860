export class UserInfo
{
	public user_info_id : number;
	public user_id      : any;
	public city_id			: any;
	public id_type_id		: any;
	public id_number		: string;
	public name					: string;
	public last_name		: string;
	public birthday			: string;
	public mobile				: string;

	constructor()
	{
		this.user_info_id = null;
		this.user_id			= [];
		this.city_id			= [];
		this.id_type_id   = [];
		this.id_number		= '';
		this.name					= '';
		this.last_name		= '';
		this.birthday			= '';
		this.mobile				= '';
	}

	setUserInfo(userInfo) 
	{
		this.user_info_id = null;
		this.user_id			= [];
		this.city_id			= [];
		this.id_type_id		= [];
		this.id_number		= '';
		this.name					= '';
		this.last_name		= '';
		this.birthday			= '';
		this.mobile				= '';
	}

	gCreate()
	{
		return {
		
		};
	}

	gUpdate()
	{
		return {
		
		};
	}
}
