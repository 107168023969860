import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-right-sidebar',
  templateUrl: './right-sidebar.component.html'
})
export class RightSidebarComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
  }

}