import { Component, OnInit } from '@angular/core';
import { UtilsService } from '../../services/utils.service';
import { SessionService } from '../../services/session.service';

declare let $;
declare let Morris;

@Component
({
    selector: 'app-stats-records',
    templateUrl: './records.component.html',
    styleUrls: ['./records.component.scss']
})

export class StatsRecordsComponent implements OnInit {
    private applications = [];
    private active = 0;
    private disableds = 0;
    public showActive = true;
    public showInactive = true;
    public ifApplications = false;
    public ifDataApplication = false;
    public dataTableHead: any = [];
    public dataTableValues: any = [];
    public dataTableRows: any = [];
    public dataTable: any = [];
    public color = {
        false: 'grey',
        true: 'green'
    };
    public activeStatus = {
        false: 'showInactive',
        true: 'showActive'
    };

    constructor (
        private session: SessionService,
        private utils: UtilsService
    ) { }

    ngOnInit() {
        this.loadApplications();
    }

    loadApplications() {
        const self = this;
        $('.preloader-content').fadeIn();

        this.utils.httpGet(false, false, 'api/stats/records').then(function (r) {
            if (r) {
                self.utils.ccHttpPost(false, false, 'api/cuf/records', {'application_id': r['application_id']}).then(function (r2) {
                    if (r2) {
                        for (const app of r['info']) {
                            self.applications.push({
                                'application_id': app['application_id'],
                                'name': app['name'],
                                'active': app['active'],
                                'app': r2[app['application_id']]['app'],
                                'web': r2[app['application_id']]['web'],
                                'total': r2[app['application_id']]['total']
                            });

                            if (app['active']) {
                                self.active += 1;
                            } else {
                                self.disableds += 1;
                            }
                        }
                        self.ifApplications = true;
                    }

                    setTimeout(function() { self.loadDonuts(); }, 3000);
                });
            }
        });
    }

    loadDonuts() {
        const self = this;

        for (const application of self.applications) {
            Morris.Bar({
                element: 'donut-' + application['application_id'],
                data: [
                    {y: 'Total registros', a: application['web'], b: application['app']}
                ],
                barColors: [
                    '#00BCD4',
                    '#1F91F3'
                ],
                // resize : true,
                xkey: 'y',
                ykeys: ['a', 'b'],
                labels: ['WEB', 'APP'],
                hideHover: true
            });
        }

        $('.preloader-content').fadeOut();
    }

    showCampaigns(type: string) {
        this.ifDataApplication = false;
        switch (type) {
            case 'Active':
                console.log('Active:' + type);
                $('.showActive').fadeIn();
                $('.showInactive').fadeOut();
                break;
            case 'Inactive':
                console.log('Inactive:' + type);
                $('.showActive').fadeOut();
                $('.showInactive').fadeIn();
                break;
            default:
                console.log('Default:' + type);
                $('.showActive').fadeIn();
                $('.showInactive').fadeIn();
        }
    }

    selectCampaign(id: any) {
        const self = this;
        self.ifDataApplication = false;

        $('.bar').fadeOut();
        $('.campaign-' + id).fadeIn();

        const x = new Date();
        const y = x.getFullYear().toString();
        let m = (x.getMonth() + 1).toString();
        let d = (x.getDate() + 1).toString();
        d = d.length === 1 ? '0' + d : d;
        m = m.length === 1 ? '0' + m : m;
        const data = {
            'app': id
            //'date_start': '1990-01-01',
            //'date_end': y + '-' + m + '-' + d,
        };

        self.dataTableHead = [];
        self.dataTableRows = [];
        self.dataTableValues = [];
        $('.preloader-content').fadeIn();

        this.utils.httpGet(false, false, 'api/application/' +data.app).then(function(r2) {
          if (r2) {
            //console.log(r2);
                for (let titulo of r2['field_id']) {
                    if (titulo.name.includes('extra')) {
                        //console.log(titulo.name);
                        const newName = titulo.name.replace('extra[', '').replace(']', '');
                        //console.log(newName);
                        self.dataTableRows.push(newName);
                    } else {
                      self.dataTableRows.push(titulo.name);
                      //self.dataTableRows.push('city');
                    }
              }

                self.dataTableRows.push('city');
                self.dataTableRows.push('created_at');
                self.dataTableRows.push('updated_at');
                
               self.dataTableHead = r2['field_id'];
                self.dataTableHead = [
                  ...self.dataTableHead,
                  { 'label': 'Nombre Ciudad' },
                  { 'label': 'Registro Cheil Connect' },
                  { 'label': 'Registro Campaña' }
                    
                ]
                //console.log(self.dataTableRows);
                self.ifDataApplication = true;
                                                                      
			}
		}).then(
            function ()  {
                self.utils.ccHttpPost(true, false, 'api/log_users', data).then(function (r) {
                    if (r) {
                        //console.log(r);
                        self.dataTableValues = r;
                        self.ifDataApplication = true;
                        self.session.loadScript(self.session.getCmsUrl() + '/assets/Customjs/pages/tables/jquery-datatable.js');

                        $('.preloader-content').fadeOut();
                    }
      
                });
            }
        );
    }
}
