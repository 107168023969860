import { 
					Directive, 
					ElementRef, 
					Renderer, 
					Input 
			 }					 from '@angular/core';
import { Location} from '@angular/common';

@Directive({
	selector : '[back]'
})

export class BackDirective 
{
	private back_html : string;

	constructor
	(
		private el			 : ElementRef,
		private re			 : Renderer,
		private location : Location
	)
	{ 
		re.listen(el.nativeElement, 'click', (event) => {this.back()});
	}

	ngAfterViewInit()
	{
		this.back_html = '<a class="a-tooltip"><span class="c-tooltip-wrapper"><span class="c-tooltip">Volver</span></span><i class="material-icons">arrow_back</i></a>';

		this.el.nativeElement.insertAdjacentHTML('beforeend', this.back_html);
	}

	back()
	{
		this.location.back();
	}
}
