import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from '../services/session.service';
import { UtilsService } from '../services/utils.service';
import { User } from '../models/user';

declare var $;

@Component({
    selector: 'app-agent',
    templateUrl: './agent.component.html'
})

export class AgentComponent implements OnInit {
    private users = [];
    private user = new User();
    private param: any;
    private regs: any = [];

    constructor
    (
        private title: Title,
        private router: Router,
        private route: ActivatedRoute,
        private session: SessionService,
        private utils: UtilsService
    ) { }

    ngOnInit() {
        this.title.setTitle('Formularios Únicos - Promotores');
        $('.date').bootstrapMaterialDatePicker({format: 'YYYY-MM-DD', time: false});
        this.getUsers();
        this.getRegs();
    }

    getUsers() {
        const self = this;

        self.utils.httpGet(false, false, 'api/user/custom/agent/').then(function(r) {
            if (r) { Object.keys(r).map(function(i) { self.users.push(r[i]); }); }
        });
    }

    getRegs() {
        const self = this;

        self.utils.ccHttpGet(true, false, 'api/cuf/regs_total/').then(function(r) {
            if (r) {
                self.regs = r;
            }

            self.session.loadScript(self.session.getCmsUrl() + '/assets/Customjs/pages/tables/jquery-datatable.js');
        });
    }
}
