export class Application
{
    public application_id    : any;
    public client_id    : any;
    public division_id  : any;
    public field_id		  : any;
    public ally_id		  : any;
    public agent_id		  : any;
    public key				  : string;
    public name				  : string;
    public path				  : string;
    public analytics	  : string;
    public environment  : boolean;
    public email_img	  : File;
    public main_m_img	  : File;
    public main_d_img	  : File;
    public web					: string;
    public mobile       : string;
    public description  : string;
    public title			  : string;
    public start_at		  : string;
    public end_at		    : string;
    public politics		  : string;
    public tc					  : File;
    public exp				  : boolean;
    public exp_title	  : string;
    public exp_m_img	  : File;
    public exp_d_img	  : File;
    public exp_count	  : string;
    public exp_form	    : string;
    public close    	  : boolean;
    public close_m_img  : File;
    public close_d_img  : File;
    public close_at     : string;
    public s_mm					: string;
    public s_md					: string;
    public s_tc					: string;
    public s_em					: string;
    public s_ed					: string;
    public s_cm					: string;
    public s_cd					: string;
    public email_active : number;
    public email_json: string;
    public email_content: any;
    public email_subject: string;
    public file : any;
    
    

    constructor()
    {
        this.application_id = null;
        this.client_id    = [];
        this.division_id  = [];
        this.field_id		  = [];
        this.ally_id		  = [];
        this.agent_id		  = [];
        this.key				  = '';
        this.name				  = '';
        this.path				  = '';
        this.analytics	  = '';
        this.environment  = false;
        this.email_img	  = null;
        this.main_m_img	  = null;
        this.main_d_img	  = null;
        this.web          = '';
        this.mobile       = '';
        this.description  = '';
        this.title = '';
        this.start_at		  = '';
        this.end_at		    = '';
        this.politics		  = '';
        this.tc					  = null;
        this.exp				  = false;
        this.exp_title		  = '';
        this.exp_m_img	  = null;
        this.exp_d_img	  = null;
        this.exp_count	  = '';
        this.exp_form 	  = '';
        this.close			  = false;
        this.close_m_img  = null;
        this.close_d_img  = null;
        this.close_at     = '';
        this.s_mm					= '';
        this.s_md					= '';
        this.s_tc					= '';
        this.s_em					= '';
        this.s_ed					= '';
        this.s_cm					= '';
        this.s_cd					= '';
        this.file = null;
    }

    setApplication(application)
    {
        this.application_id    = (application.application_id) ? application.application_id : null;
        this.client_id    = (application.client_id) ? application.client_id : [];
        this.division_id  = (application.division_id) ? application.division_id : [];
        this.field_id		  = (application.field_id) ? application.field_id : [];
        this.ally_id		  = (application.ally_id) ? application.ally_id : [];
        //this.agent_id		  = (application.agent_id) ? application.agent_id : [];
        this.key				  = application.key;
        this.name				  = application.name;
        this.path				  = application.path;
        this.analytics	  = application.analytics;
        this.environment  = application.environment;
        this.email_img	  = application.email_img;
        this.main_m_img	  = application.main_m_img;
        this.main_d_img	  = application.main_d_img;
        this.web					= application.web;
        this.mobile				= application.mobile;
        this.description  = application.description;

        this.email_active  = application.email_active;
        this.email_json  = application.email_json;
        this.email_content  = application.email_content;
        this.email_subject  = application.email_subject;
        this.file	  = application.attachments;

        this.title = application.title;
        this.start_at		  = application.start_at;
        this.end_at		    = application.end_at;
        this.tc					  = application.tc;
        this.exp				  = application.exp;
        this.exp_title		  = application.exp_title;
        this.exp_m_img	  = application.exp_m_img;
        this.exp_d_img	  = application.exp_d_img;
        this.exp_count	  = application.exp_count;
        this.exp_form 	  = application.exp_form;
        this.close			  = application.close;
        this.close_m_img  = application.close_m_img;
        this.close_d_img  = application.close_d_img;
        this.close_at     = application.close_at;
        this.s_mm 				= application.s_mm;
        this.s_md					= application.s_md;
        this.s_tc					= application.s_tc;
        this.s_em					= application.s_em;
        this.s_ed					= application.s_ed;
        this.s_cm					= application.s_cm;
        this.s_cd					= application.s_cd;
    }

    gCreate(application)
    {
        const form_data = new FormData();

        form_data.append('client_id', application.client_id['client_id']);
        form_data.append('name', application.name);
        form_data.append('path', application.path);
        form_data.append('email_img', application.email_img);
        form_data.append('main_m_img', application.main_m_img);
        form_data.append('main_d_img', application.main_d_img);
        form_data.append('web', (application.web) ? '1' : '0');
        form_data.append('mobile', (application.mobile) ? '1' : '0');
        form_data.append('description', application.description);
        form_data.append('title', application.title);
        form_data.append('start_at', application.start_at);
        form_data.append('end_at', application.end_at);
        form_data.append('tc', application.tc);

        if (application.application_id !== null) form_data.append('update', '1');

        if (application.exp) {
            form_data.append('exp', application.exp);
            form_data.append('exp_m_img', application.exp_m_img);
            form_data.append('exp_d_img', application.exp_d_img);
            form_data.append('exp_title', application.exp_title);
            form_data.append('exp_count', (application.exp_count) ? '1' : '0');
            form_data.append('exp_form', (application.exp_form) ? '1' : '0');
        }

        if (application.close) {
            form_data.append('close', application.close);
            form_data.append('close_at', application.close_at);
            form_data.append('close_m_img', application.close_m_img);
            form_data.append('close_d_img', application.close_d_img);
        }

        form_data.append('field_id[]', '1');
        for (const d of application.division_id) form_data.append('division_id[]', d);

        for (const d of application.field_id) {
            form_data.append('field_id[]', d);
            if (d == 7 || d == 19) form_data.append('field_id[]', '6');
        }

        if (application.ally_id.length > 0) for (const d of application.ally_id) form_data.append('ally_id[]', d);
        if (application.agent_id.length > 0) for (const d of application.agent_id) form_data.append('agent_id[]', d);

        return form_data;
    }

    gCreateBasic(application) {

        const form_data = new FormData();

        form_data.append('client_id', application.client_id['client_id']);
        form_data.append('name', application.name);
        form_data.append('path', application.path);
        form_data.append('description', application.description);
        form_data.append('division_id', '6');
        form_data.append('field_id[]', '1');

        //for (const d of application.division_id) form_data.append('division_id[]', d);
        

        for (const d of application.field_id) {
            form_data.append('field_id[]', d);
            if (d == 7 || d == 19) form_data.append('field_id[]', '6');
        }

        return form_data;
        
    }


    

   

    gUpdate(application, application_id) {
        const form_data = new FormData();

        form_data.append('update', '1');
        form_data.append('application_id', application_id);
        form_data.append('client_id', application.client_id['client_id']);
        form_data.append('name', application.name);
        form_data.append('path', application.path);
        form_data.append('web', (application.web) ? '1' : '0');
        form_data.append('mobile', (application.mobile) ? '1' : '0');
        form_data.append('description', application.description);
        form_data.append('title', application.title);
        form_data.append('start_at', application.start_at);
        form_data.append('end_at', application.end_at);

        if (sessionStorage.getItem('email') == 'admin@admin.admin' && application_id > 0) {
            form_data.append('environment', (application.environment) ? '1' : '0');
        }

        if (application.s_e) form_data.append('email_img', application.email_img);
        if (application.s_mm) form_data.append('main_m_img', application.main_m_img);
        if (application.s_md) form_data.append('main_d_img', application.main_d_img);
        if (application.s_tc) form_data.append('tc', application.tc);

        if (application.exp) {
            form_data.append('exp', application.exp);
            if (application.s_em) form_data.append('exp_m_img', application.exp_m_img);
            if (application.s_ed) form_data.append('exp_d_img', application.exp_d_img);
            if (application.exp_title) form_data.append('exp_title', application.exp_title);
            form_data.append('exp_count', (application.exp_count) ? '1' : '0');
            form_data.append('exp_form', (application.exp_form) ? '1' : '0');
        }

        if (application.close) {
            form_data.append('close', application.close);
            form_data.append('close_at', application.close_at);
            if (application.s_cm) form_data.append('close_m_img', application.close_m_img);
            if (application.s_cd) form_data.append('close_d_img', application.close_d_img);
        }

        form_data.append('field_id[]', '1');
        for (const d of application.division_id) form_data.append('division_id[]', d);

        for (const d of application.field_id) {
            form_data.append('field_id[]', d);
            if (d == 7 || d == 19) form_data.append('field_id[]', '6');
        }

        if (application.ally_id.length > 0) for (const d of application.ally_id) form_data.append('ally_id[]', d);
        if (application.agent_id.length > 0) for (const d of application.agent_id) form_data.append('agent_id[]', d);

        return form_data;
    }

    gAnalytics(analytics) {
        return {
            'analytics' : analytics
        };
    }
}
