import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '../../services/session.service';
import { PermissionService } from 'angular2-permission/dist';
import { isUndefined } from 'util';

declare var jquery: any;
declare var $: any;

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html'
})

export class MenuComponent implements OnInit {
    private email: string;
    private name: string;
    private last_name: string;
    private ur_name: string;
    private user_id: string;
    private showMenu = false;

    constructor (
        private router: Router,
        private permission: PermissionService,
        private session: SessionService
    ) {}

    ngOnInit() {
        this.email = sessionStorage.getItem('email');
        this.name = sessionStorage.getItem('name');
        this.last_name = sessionStorage.getItem('last_name');
        this.ur_name = sessionStorage.getItem('user_rol_name');
        this.user_id = sessionStorage.getItem('user_id');
        this.showMenu = true;

        this.permission.add(sessionStorage.getItem('user_rol_id'));
        this.session.loadScript(this.session.getCmsUrl() + '/assets/Customjs/default.js');
    }

    logout() {
        sessionStorage.clear();
        this.permission.clearStore();
        this.router.navigate(['login']);
    }
}
