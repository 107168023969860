import { Component, OnInit } from '@angular/core';

declare var $;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

    private title = 'ADMINISTRADOR - FORMULARIOS ÚNICOS'; // SIN RIESGOSAMSUNG PLUS

    constructor() { }

    ngOnInit() {

    }

		menu()
		{
			$('.m-menu').toggleClass('active');
			$('#leftsidebar').toggleClass('active');
		}

}
