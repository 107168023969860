import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PermissionService } from 'angular2-permission/dist';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class SessionService {
    private isLocal = false;
    private isDebug = false;
    private loggedIn = false;
    public url = 'https://frm.api.samsung.com.co/'; //production
    //public url = 'http://dev.frm-api.cheil.com.co/'; //staging
    private url2 = 'http://localhost:8000/';
    private cms_url = 'https://labusquedas8.com/test_cuf';
    private ccurl = 'https://live.connect.cheil.com.co/'; // production
    //private ccurl = 'http://dev.connect.cheil.com.co/'; //staging
    private ccurl2 = 'http://localhost:8001/';
    private file_url: string;
    // private ccurl2   = 'https://live.connect.cheil.com.co/'


    constructor (
        public http: HttpClient,
        public permissionService: PermissionService,
        private router: Router,
    ) {
        this.url      = (this.isLocal) ? this.url2 : this.url;
        this.ccurl    = (this.isLocal) ? this.ccurl : this.ccurl;
        this.file_url = (this.isLocal) ? 'http://localhost:8000/storage/app/img/' : 'https://frm.api.samsung.com.co/storage/app/img/';
        this.cms_url  = (this.isLocal) ? 'http://localhost:4200' : 'https://frm.cms.samsung.com.co/';
        this.isLoggedIn();
    }

    isLoggedIn() {
        const self = this;

        this.http.get(
            this.url + 'api/logged_in',
            {
                headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                    .set('Authorization', 'Bearer ' + sessionStorage.getItem('token'))
            }
        ).subscribe(
            data => {
                if (this.router.url === '/login') { this.router.navigate(['home']); }
            },
            err => {
                if (this.router.url.indexOf('recover') === -1) { self.logout(); }
            }
        );
    }

    getUrl(): string {
        return this.url;
    }

    getCCUrl(): string {
        return this.ccurl;
    }

    getFileUrl(): string {
        return this.file_url;
    }

    getCmsUrl(): string {
        return this.cms_url;
    }

    loadScript(url) {
        const node = document.createElement('script');
        node.type  = 'text/javascript';
        node.src   = url;
        document.getElementsByTagName('head')[0].appendChild(node);
    }

    debugConsole (message) {
        if (this.isDebug === true) {
            console.log(message);
        } else {
            alert (message);
        }
    }

    logout () {
        sessionStorage.clear();
        this.router.navigate(['login']);
    }
}
