import { Component, OnInit }			 from '@angular/core';
import { Router, ActivatedRoute }  from '@angular/router';
import { UtilsService }            from '../../services/utils.service';
import { User }										 from '../../models/user';

@Component({
  selector		: 'app-user-show',
  templateUrl	: './show.component.html'
})

export class UserShowComponent implements OnInit 
{
	private user		= new User();
	private user_id = 0;
	private param		: any;
	private genders	: any = [];

  constructor
	(
    private router : Router,
    private route	 : ActivatedRoute,
		private utils	 : UtilsService,
	) { }

  ngOnInit() 
	{
		this.param = this.route.params.subscribe(
			params => {
				this.user_id = params['id'];
			}
		);

		this.genders['M'] = 'Masculino';
		this.genders['F'] = 'Femenino';
		this.genders['O'] = 'Otro';
		this.getUser();
  }

	getUser()
	{
		var self = this;

		this.utils.httpGet(true, false, 'api/user/' + this.user_id).then(function(r) {
			if (r) self.user.setUser(r);
		});
	}
}
