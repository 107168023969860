import { Component, OnInit }			 from '@angular/core';
import { Router, ActivatedRoute }  from '@angular/router';
import { UtilsService }            from '../../services/utils.service';
import { Application }						 from '../../models/application';

@Component({
  selector		: 'app-show',
  templateUrl	: './show.component.html'
})

export class ApplicationShowComponent implements OnInit 
{
	private application  	 = new Application();
	private application_id = 0;
	private agents         = [];
	private param					 : any;

  constructor
	(
    private router : Router,
    private route	 : ActivatedRoute,
		private utils	 : UtilsService
	) { }

  ngOnInit() 
	{
		this.param = this.route.params.subscribe(
			params => {
				this.application_id = params['id']
			}
		);

		this.getApplication();
  }

	getApplication()
	{
		var self = this;

		this.utils.httpGet(true, false, 'api/application/' + this.application_id).then(function(r) {
			if (r) {
				self.application.setApplication(r);
				delete(r['agents']['user_id']);
				Object.keys(r['agents']).map(function(i) { self.agents[i] = r['agents'][i]['name'].toString(); });
			}
		});
	}
}
