export class User 
{
	public user_id				: number;
	public user_rol_id		: any;
	public client_id			: any;
	public ally_id				: any;
	public user_name			: string;
	public email					: string;
	public password				: string;
	public reset_password : string;
	public remember_token : string;
	public state				  : string;
	public name						: string;
	public last_name			: string;
	public birthday				: string;
	public id_type_id 		: any;
	public id_type_name		: string;
	public id_number			: string;
	public mobile					: string;
	public address			  : string;
	public gender					: string;
	public city_id			  : any;
	public city_name			: string;
	public department_id	: number;
	public application_id : any;

	constructor() 
	{
		this.user_id				 = null;
		this.user_rol_id		 = [];
		this.client_id			 = [];
		this.ally_id			   = [];
		this.ally_id.ally_id = null;
		this.user_name			 = '';
		this.email					 = '';
		this.password				 = '';
		this.reset_password  = '';
		this.remember_token  = '';
		this.state					 = '';
		this.name						 = '';
		this.last_name			 = '';
		this.birthday				 = '';
		this.id_type_id			 = [];
		this.id_type_name		 = '';
		this.id_number			 = '';
		this.mobile					 = '';
		this.address				 = '';
		this.gender					 = '';
		this.city_id				 = [];
		this.city_name		   = '';
		this.department_id	 = null;
		this.application_id  = [];
	}

	setUser(user) 
	{
		this.user_id				= user.user_id;
		this.user_rol_id		= user.user_rol_id;
		this.client_id			= (user.client_id) ? user.client_id : [];
		this.ally_id				= (user.ally_id) ? user.ally_id : [];
		this.user_name			= user.user_name;
		this.email					= user.email;
		this.password				= user.password;
		this.reset_password = user.reset_password;
		this.remember_token = user.remember_token;
		this.state					= user.state;
		this.name						= user.name;
		this.last_name			= user.last_name;
		this.birthday				= user.birthday;
		this.id_type_id			= user.id_type_id;
		this.id_type_name		= user.id_type_name;
		this.id_number			= user.id_number;
		this.mobile					= user.mobile;
		this.gender					= user.gender;
		this.city_name			= user.city_name;
		this.department_id = user.department_id;
	}

	gCreate(user) 
	{
		return {
			'user_rol_id' : user.user_rol_id['user_rol_id'],
			'client_id'		: user.client_id['client_id'],
			'email'				: user.email,
			'password'		: user.password,
			'name'				: user.name,
			'last_name'		: user.last_name,
			'birthday'		: user.birthday,
			'id_type_id'  : user.id_type_id,
			'id_number'		: user.id_number,
			'mobile'			: user.mobile,
			'address'			: (user.address) ? user.address : '',
			'gender'		  : user.gender,			
			'city_id'			: user.city_id['city_id']
		};	
	}

	gUpdate(user)
	{
		return {
			'user_rol_id' : user.user_rol_id['user_rol_id'],
			'client_id'		: user.client_id['client_id'],
			'password'		: user.password,
			'state' 			: (user.state) ? '1' : '0',
			'name'				: user.name,
			'last_name'		: user.last_name,
			'birthday'		: user.birthday,
			'id_type_id'  : user.id_type_id,
			'id_number'		: user.id_number,
			'mobile'			: user.mobile,
			'gender'			: user.gender,
			'city_id'			: user.city_id['city_id']
		};	
	}
	
	gAgentCreate(user) 
	{
		return {
			'user_rol_id' : 5,
			'ally_id'			: user.ally_id['ally_id'],
			'email'				: user.email,
			'password'		: user.password,
			'name'				: user.name,
			'last_name'		: user.last_name,
			'birthday'		: user.birthday,
			'id_type_id'  : user.id_type_id,
			'id_number'		: user.id_number,
			'mobile'			: user.mobile,
			'address'     : user.address,
			'gender'			: user.gender,
			'city_id'			: user.city_id['city_id']
		};	
	}

	gAgentUpdate(user)
	{
		return {
			'ally_id'			: user.ally_id['ally_id'],
			'password'		: user.password,
			'state' 			: (user.state) ? '1' : '0',
			'name'				: user.name,
			'last_name'		: user.last_name,
			'birthday'		: user.birthday,
			'id_type_id'  : user.id_type_id,
			'id_number'		: user.id_number,
			'mobile'			: user.mobile,
			'address'     : user.address,
			'gender' : user.gender,
			'city_id'			: user.city_id['city_id']
		};	
	}

	gAgentApps(user)
	{
		return {
			'application_id' : user.application_id,
			'ally_id'				 : user.ally_id['ally_id']
		};
	}

	gPassword(password)
	{
		return {
			'password' : password
		};
	}

	gRecover(email, id_number)
	{
		return  {
			'email'		  : email,
			'id_number' : id_number
		};
	}
}
